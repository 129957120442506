//method, an extra layer to make sure user is selecting either State or Zip
export const validateSearchCriteria = (selectedState, zip, distance, isLoading, setValidationError) => {
    if (isLoading) return true;

    setValidationError('');

    if (selectedState) {
        return true;
    }

    if (zip && distance) {
        return true;
    }

    if (!selectedState && !zip) {
        setValidationError('Please select either a State or enter a ZIP code to search.');
        return false;
    }

    if (zip && !distance) {
        setValidationError('Distance is required when searching by ZIP code');
        return false;
    }

    if (distance && !zip) {
        setValidationError('ZIP code is required when specifying distance');
        return false;
    }

    return true;
};