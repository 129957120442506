import React, { useState, useEffect, useMemo } from 'react';
import { useCallback } from 'react';
import './artClinics.scss';
import './Footnotes/footNotes.scss';
import SearchForm from './SearchForm/SearchForm';
import ClinicResultsView from './ClinicResultsView/ClinicResultsView ';
import RelatedLinks from '../RelatedLinks';
import {
    stateMapping,
    getStateAbbreviation,
    getStateFullName
} from './Utility/stateUtils';
import { createServiceMappings } from './Utility/serviceUtils';
import { updateURLAndParams } from './Utility/urlUtils';
import { validateSearchCriteria } from './Utility/validationUtils';
import { filterClinicData } from './Utility/filterUtils';
import Footnotes from './Footnotes/Footnotes';
import Spinner from '../spinner/Spinner';


const ArtClinics = () => {
    // State declarations for managing component data and UI state
    const [maxClinicYear, setMaxClinicYear] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [zip, setZip] = useState('');
    const [distance, setDistance] = useState('');
    const [clinicData, setClinicData] = useState(null);
    const [footNotes, setFootNotes] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
    const [validationError, setValidationError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedProfiles, setSelectedProfiles] = useState([]);
    const [unfilteredData, setUnfilteredData] = useState(null);
    const [stateTerritories, setStateTerritories] = useState({
        states: [],
        territories: []
    });
    const [selectedStateFullName, setSelectedStateFullName] = useState('');
    const [hasSearched, setHasSearched] = useState(false);
    const [lastSearchedStateName, setLastSearchedStateName] = useState('');
    const [lastSearchedZip, setLastSearchedZip] = useState('');
    const [lastSearchedDistance, setLastSearchedDistance] = useState('');
    const [isInitializing, setIsInitializing] = useState(true);
    const [componentLoading, setComponentLoading] = useState({
        searchForm: true,
        clinicResults: true
    });
    const [servicesMetadata, setServicesMetadata] = useState([]);
    const [profilesMetadata, setProfilesMetadata] = useState([]);
    const [availableSubTopics, setAvailableSubTopics] = useState([]);
    const [serviceLabels, setServiceLabels] = useState({});
    const [availableProfiles, setAvailableProfiles] = useState([]);
    const [profileLabels, setProfileLabels] = useState({});

    const itemsPerPage = 25;
    const fetchUriPrefix = process.env.REACT_APP_FETCH_URI_PREFIX || '';

    const getFetchConfig = () => {
        return {
            headers: {
                'X-Database-Type': 'art', // Since this is ArtClinics component, we always use 'art'
                'Content-Type': 'application/json'
            },
            signal: AbortSignal.timeout(300000)
        };
    };

    // Determines if search is enabled based on state or zip selection
    const isSearchEnabled = useCallback(() => {
        return !!selectedState || !!zip;
    }, [selectedState, zip]);

    // Extracts and processes search parameters from the URL
    const getParamsFromUrl = useCallback(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const stateAbbr = queryParams.get('State');
        const zipCode = queryParams.get('Zip');
        let dist = queryParams.get('Distance');

        const subTopics = queryParams.get('SubTopic') ?
            queryParams.get('SubTopic').split(',') :
            [];

        // Filter subtopics based on dynamic available services and profiles
        const services = subTopics.filter(id => availableSubTopics.includes(id));
        const profiles = subTopics.filter(id => availableProfiles.includes(id));

        // If no URL params, check session storage
        if (!stateAbbr && !zipCode) {
            const sessionState = sessionStorage.getItem('selectedState');
            const sessionStateAbbr = sessionStorage.getItem('selectedStateAbbr');
            const sessionZip = sessionStorage.getItem('zip');
            const sessionDistance = sessionStorage.getItem('distance');
            if (sessionState) {
                return {
                    state: sessionState,
                    stateAbbr: sessionStateAbbr || getStateAbbreviation(sessionState),
                    zip: '',
                    distance: '',
                    services,
                    profiles
                };
            } else if (sessionZip) {
                setLastSearchedZip(sessionZip);
                setLastSearchedDistance(sessionDistance);
                return {
                    state: '',
                    stateAbbr: '',
                    zip: sessionZip,
                    distance: sessionDistance || '50',
                    services,
                    profiles
                };
            }
        }

        if (stateAbbr) {
            const stateName = getStateFullName(stateAbbr);
            return {
                state: stateName,
                stateAbbr: stateAbbr,
                zip: '',
                distance: '',
                services,
                profiles
            };
        } else if (zipCode) {
            if (!dist) {
                dist = '50';
                const url = new URL(window.location);
                url.searchParams.set('Distance', '50');
                window.history.replaceState({}, '', url);
            }
            setLastSearchedZip(zipCode);
            setLastSearchedDistance(dist);
            return {
                state: '',
                stateAbbr: '',
                zip: zipCode,
                distance: dist,
                services,
                profiles
            };
        }

        return {
            state: stateAbbr ? getStateFullName(stateAbbr) : '',
            stateAbbr,
            zip: zipCode || '',
            distance: dist || '',
            services,
            profiles
        };
    }, []);

    // Handles search initiated from URL parameters
    const handleUrlSearch = useCallback(async () => {
        // Set component loading state at the start
        setComponentLoading(prev => ({
            ...prev,
            clinicResults: true
        }));

        if (stateTerritories.states.length === 0) {
            // Add a retry mechanism if states aren't loaded yet
            let retries = 0;
            const maxRetries = 3;
            const retryInterval = 500; // 500ms between retries

            const waitForStates = async () => {
                while (retries < maxRetries && stateTerritories.states.length === 0) {
                    await new Promise(resolve => setTimeout(resolve, retryInterval));
                    retries++;
                }
            };

            await waitForStates();
            if (stateTerritories.states.length === 0) {
                setComponentLoading(prev => ({
                    ...prev,
                    clinicResults: false
                }));
                return; // Exit if states still haven't loaded after retries
            }
        }

        const urlParams = getParamsFromUrl();

        if (!urlParams.state && !urlParams.zip) {
            setComponentLoading(prev => ({
                ...prev,
                clinicResults: false
            }));
            return;
        }

        setValidationError('');
        setError(null);

        if (urlParams.state) {
            const matchingState = [...stateTerritories.states, ...stateTerritories.territories].find(
                state => state.stateAbbr === urlParams.stateAbbr
            );

            if (matchingState) {
                await new Promise(resolve => setTimeout(resolve, 100));
                setSelectedState(urlParams.stateAbbr);
                setSelectedStateFullName(matchingState.state);
                setLastSearchedStateName(matchingState.state);
                setZip('');
                setDistance('');
            }
        } else if (urlParams.zip) {
            setSelectedState('');
            setSelectedStateFullName('');
            setZip(urlParams.zip);
            setDistance(urlParams.distance);
        }

        setSelectedServices(urlParams.services);
        setSelectedProfiles(urlParams.profiles);
        setHasSearched(true);
        setIsLoading(true);

        try {
            const params = new URLSearchParams();

            if (urlParams.state) {
                params.append('stateAbbr', urlParams.stateAbbr);
            } else if (urlParams.zip) {
                params.append('zip', urlParams.zip);
                params.append('distance', urlParams.distance);
            }

            const allSubTopics = [...urlParams.services, ...urlParams.profiles];
            if (allSubTopics.length > 0) {
                params.append('subTopicIds', allSubTopics.join(','));
            }

            const endpoint = `${fetchUriPrefix}/api/ClinicListByLocation/location`;
            const fullUrl = `${endpoint}${params.toString() ? '?' + params.toString() : ''}`;

            const response = await fetch(fullUrl, getFetchConfig());

            if (!response.ok) {
                throw new Error('Failed to fetch clinic data');
            }

            const data = await response.json();
            let sortedClinics = data.Clinics || [];

            // Sort based on query type
            if (urlParams.state) {
                // Sort by facility name when searching by state
                sortedClinics = sortedClinics.sort((a, b) =>
                    a.FacilityName.localeCompare(b.FacilityName)
                );
            } else if (urlParams.zip) {
                // Sort by distance when searching by zip
                sortedClinics = sortedClinics.sort((a, b) => {
                    const distanceA = parseFloat(a.Distance) || 0;
                    const distanceB = parseFloat(b.Distance) || 0;
                    return distanceA - distanceB;
                });
            }

            setUnfilteredData(sortedClinics);
            setClinicData(sortedClinics);
            setFootNotes(data.Footnotes);
        } catch (error) {
            setError(error.message);
            console.error('Error fetching clinic data:', error);
        } finally {
            setIsLoading(false);
            setComponentLoading(prev => ({
                ...prev,
                clinicResults: false
            }));
        }
    }, [fetchUriPrefix, getParamsFromUrl, stateTerritories]);

    // Calculates the current page's data based on pagination settings
    const paginatedData = useMemo(() => {
        if (!clinicData) return [];
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return clinicData.slice(startIndex, endIndex);
    }, [clinicData, currentPage]);

    // Calculates total number of pages based on data length and items per page
    const totalPages = useMemo(() => {
        if (!clinicData) return 0;
        return Math.ceil(clinicData.length / itemsPerPage);
    }, [clinicData]);

    // Handles page navigation and scrolls to results section
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        document.querySelector('.results-section')?.scrollIntoView({ behavior: 'smooth' });
    };

    // Initiates clinic search with current filters
    const handleSearch = () => {
        setCurrentPage(1);
        setLastSearchedStateName(selectedStateFullName);
        setLastSearchedZip(zip);
        setLastSearchedDistance(distance);
        fetchClinics();
    };

    // Resets all search parameters and filters
    const handleClear = () => {
        setCurrentPage(1);
        setSelectedState('');
        setZip('');
        setDistance('');
        setClinicData(null);
        setFootNotes(null);
        setUnfilteredData(null);
        setValidationError('');
        setSelectedServices([]);
        setSelectedProfiles([]);
        setLastSearchedStateName('');
        setLastSearchedZip('');
        setLastSearchedDistance('');
        const url = new URL(window.location);
        url.search = '';
        window.history.pushState({}, '', url);
    };

    // Initializes search on component mount and URL changes
    useEffect(() => {
        handleUrlSearch();
        setIsInitialLoadComplete(true);
    }, [handleUrlSearch]);

    // Handles browser back/forward navigation
    useEffect(() => {
        const handlePopState = () => {
            handleUrlSearch();
        };

        window.addEventListener('popstate', handlePopState);
        return () => window.removeEventListener('popstate', handlePopState);
    }, [handleUrlSearch]);

    // Get clinic data from the API (/api/ClinicListByLocation/location )based on search criteria
    const fetchClinics = async (isFromUrl = false) => {
        if (!isFromUrl && !validateSearchCriteria(selectedState, zip, distance, isLoading, setValidationError)) {
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const params = new URLSearchParams();

            if (selectedState) {
                params.append('stateAbbr', getStateAbbreviation(selectedState));
            }

            if (zip) {
                params.append('zip', zip);
            }

            if (distance) {
                params.append('distance', distance);
            }

            if (selectedServices.length > 0) {
                params.append('subTopicIds', selectedServices.join(','));
            }

            const endpoint = `${fetchUriPrefix}/api/ClinicListByLocation/location`;
            const fullUrl = `${endpoint}${params.toString() ? '?' + params.toString() : ''}`;

            const response = await fetch(fullUrl, getFetchConfig());

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.Error || 'Failed to fetch clinic data');
            }

            const data = await response.json();
            let sortedClinics = data.Clinics || [];

            // Sort based on search type
            if (selectedState) {
                // Sort by facility name when searching by state
                sortedClinics = sortedClinics.sort((a, b) =>
                    a.FacilityName.localeCompare(b.FacilityName)
                );
            } else if (zip) {
                // Sort by distance when searching by zip
                sortedClinics = sortedClinics.sort((a, b) => {
                    const distanceA = parseFloat(a.Distance) || 0;
                    const distanceB = parseFloat(b.Distance) || 0;
                    return distanceA - distanceB;
                });
            }

            setUnfilteredData(sortedClinics);
            setClinicData(sortedClinics);
            setFootNotes(data.Footnotes);
        } catch (error) {
            setError(error.message);
            console.error('Error fetching clinic data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Handles state selection changes and updates URL
    const handleStateChange = (e) => {
        if (!isInitialLoadComplete) return;
        //console.log('State Change Event:', e.target);
        const selectedOption = e.target.options[e.target.selectedIndex];
        const fullStateNameNoAbbreviation = selectedOption.text;
        const fullStateName = e.target.value;

        setSelectedState(fullStateName);
        setSelectedStateFullName(fullStateNameNoAbbreviation);
        setZip('');
        setDistance('');

        sessionStorage.setItem('selectedState', fullStateNameNoAbbreviation);
        sessionStorage.setItem('selectedStateAbbr', fullStateName);
        sessionStorage.removeItem('zip');
        sessionStorage.removeItem('distance');

        updateURLAndParams('State', getStateAbbreviation(fullStateName), isInitialLoadComplete);
    };

    // Handles zip code input changes and updates URL
    const handleZipChange = (e) => {
        const value = e.target.value.replace(/\D/g, '').slice(0, 5);
        setZip(value);
        setSelectedState('');
        setSelectedStateFullName('');

        // Save zip/distance and clear state in session
        sessionStorage.setItem('zip', value);
        if (!distance) {
            sessionStorage.setItem('distance', '50');
        }
        sessionStorage.removeItem('selectedState');

        const url = new URL(window.location);
        url.searchParams.set('Zip', value);
        url.searchParams.delete('State');

        if (value && !distance) {
            setDistance('50');
            url.searchParams.set('Distance', '50');
        }

        window.history.pushState({}, '', url);
    };

    // Handles distance selection changes and updates URL
    const handleDistanceChange = (e) => {
        const value = e.target.value;
        setDistance(value);
        sessionStorage.setItem('distance', value);
        updateURLAndParams('Distance', value, isInitialLoadComplete);
    };

    // Checks if any clinic in the data has distance information
    const hasDistanceData = useMemo(() => {
        if (!clinicData) return false;
        return clinicData.some(clinic =>
            clinic.Distance &&
            !isNaN(parseFloat(clinic.Distance)) &&
            parseFloat(clinic.Distance) > 0
        );
    }, [clinicData]);

    // Stores selected clinic ID in session storage when user clicks on clinic's hyper link from the table
    const handleClinicClick = (clinicId) => {
        sessionStorage.setItem('ClinicId', clinicId);
        sessionStorage.setItem('ModifyClinicId', clinicId);
    };

    // Handles filter changes and updates URL with new filter parameters
    const handleFilterChange = ({ text, services, profiles }) => {
        setSelectedServices(services);
        setSelectedProfiles(profiles);

        const allSubTopics = [...services, ...profiles];

        // Update URL
        const url = new URL(window.location);
        if (allSubTopics.length > 0) {
            url.searchParams.set('SubTopic', allSubTopics.join(','));
        } else {
            url.searchParams.delete('SubTopic');
        }
        window.history.pushState({}, '', url);

        // Filter data
        const filteredData = filterClinicData(
            unfilteredData,
            text,
            services,
            profiles,
            serviceLabels,
            profileLabels
        );

        setClinicData(filteredData);
        setCurrentPage(1);
    };

    // Get meta data for services and profiles to use in modal 
    const fetchMetadata = async () => {
        try {
            const endpoint = `${fetchUriPrefix}/api/ServicesAndProfiles/metadata/true`;
            const response = await fetch(endpoint, getFetchConfig());

            if (!response.ok) {
                throw new Error('Failed to fetch metadata');
            }

            const data = await response.json();

            // Store raw metadata
            setServicesMetadata(data.ServicesMetadata || []);
            setProfilesMetadata(data.ProfilesMetadata || []);

            // Process metadata to create dynamic mappings
            const {
                availableSubTopics,
                serviceLabels,
                availableProfiles,
                profileLabels
            } = createServiceMappings(data.ServicesMetadata || []);

            // Set all the dynamic mappings
            setAvailableSubTopics(availableSubTopics);
            setServiceLabels(serviceLabels);
            setAvailableProfiles(availableProfiles);
            setProfileLabels(profileLabels);

            // Set max year for page title
            if (data.ServicesMetadata?.length > 0) {

                setMaxClinicYear(data.ServicesMetadata[0][2]);

            }

        } catch (error) {
            console.error('Error fetching metadata:', error);
            // Initialize empty mappings in case of error
            setAvailableSubTopics([]);
            setServiceLabels({});
            setAvailableProfiles([]);
            setProfileLabels({});
        }
    };

    //get states and territories
    const fetchStateTerritories = async () => {
        try {
            const endpoint = `${fetchUriPrefix}/api/ClinicListByLocation/stateterritories`;
            const response = await fetch(endpoint, getFetchConfig());

            if (!response.ok) {
                throw new Error('Failed to fetch state territories');
            }

            const data = await response.json();

            const rawTerritories = (data.StateTerritories || [])
                .filter(item => item.stateAbbr !== 'US');

            const stateAbbreviations = [
                'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
                'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
                'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
                'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
                'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
            ];

            const groupedTerritories = {
                states: [],
                territories: []
            };

            rawTerritories.forEach(item => {
                if (stateAbbreviations.includes(item.stateAbbr)) {
                    groupedTerritories.states.push(item);
                } else {
                    groupedTerritories.territories.push(item);
                }
            });

            const organizedTerritories = {
                states: groupedTerritories.states.sort((a, b) => parseInt(a.displayOrder) - parseInt(b.displayOrder)),
                territories: groupedTerritories.territories.sort((a, b) => parseInt(a.displayOrder) - parseInt(b.displayOrder))
            };


            // Initialize state mapping first
            stateMapping.initialize([...groupedTerritories.states, ...groupedTerritories.territories]);

            // Then set the territories in state
            setStateTerritories(organizedTerritories);

            // After territories are set, check URL params
            const urlParams = new URLSearchParams(window.location.search);
            const stateParam = urlParams.get('State');

            if (stateParam) {
                const matchingState = groupedTerritories.states.find(
                    state => state.stateAbbr === stateParam
                );

                if (matchingState) {
                    // Set both state values immediately
                    setSelectedState(matchingState.state);
                    setSelectedStateFullName(matchingState.state);
                }
            }

        } catch (error) {
            console.error('Error fetching state territories:', error);
            setStateTerritories({ states: [], territories: [] });
            stateMapping.initialize([]);
        }
    };

    // main use effect
    useEffect(() => {
        const initialize = async () => {
            console.log('Initialization Starting...');
            setIsInitializing(true);
            try {
                await fetchMetadata();
                await fetchStateTerritories();
                setIsInitialLoadComplete(true);
            } catch (error) {
                console.error('Initialization error:', error);
            } finally {
                setIsInitializing(false);
                // Set individual component loading states after initialization
                setComponentLoading(prev => ({
                    ...prev,
                    searchForm: false
                }));
            }
            console.log('Initialization complete');
        };

        initialize();
    }, []);

    // Add a separate useEffect to handle URL parameters after states are loaded
    useEffect(() => {
        if (stateTerritories.states.length > 0 && isInitialLoadComplete) {
            const urlParams = new URLSearchParams(window.location.search);
            const stateParam = urlParams.get('State');

            if (stateParam) {
                setTimeout(() => {
                    // Check both states and territories arrays
                    const matchingState = [...stateTerritories.states, ...stateTerritories.territories].find(
                        state => state.stateAbbr === stateParam
                    );

                    if (matchingState) {
                        setSelectedState(matchingState.stateAbbr);
                        setSelectedStateFullName(matchingState.state);
                        setLastSearchedStateName(matchingState.state);
                        handleUrlSearch();
                    }
                }, 100);
            }
        }
    }, [stateTerritories, isInitialLoadComplete]);

    document.title = "View ART Data | Assisted Reproductive Technology (ART) Success Rates | CDC";

    return (
        <>
            <div className="art-dashboard container">
                {isInitializing ? (
                    <div className="results-section">
                        <Spinner
                            message="Loading..."
                            backGround={true}
                            center={true}
                        />
                    </div>
                ) : (
                    <>
                        <h1 className="mt-4 mb-5 text-primary">
                            View ART Data for Reporting Year {maxClinicYear}
                        </h1>

                        <SearchForm
                            validationError={validationError}
                            selectedState={selectedState}
                            zip={zip}
                            distance={distance}
                            stateTerritories={stateTerritories}
                            isSearchEnabled={isSearchEnabled}
                            handleStateChange={handleStateChange}
                            handleZipChange={handleZipChange}
                            handleDistanceChange={handleDistanceChange}
                            handleSearch={handleSearch}
                            handleClear={handleClear}
                            isLoading={componentLoading.searchForm}
                        />

                        {(unfilteredData !== null || isLoading) && (
                            <ClinicResultsView
                                isLoading={isLoading || componentLoading.clinicResults}
                                selectedState={lastSearchedStateName}
                                zip={lastSearchedZip}
                                distance={lastSearchedDistance}
                                error={error}
                                clinicData={clinicData}
                                currentPage={currentPage}
                                itemsPerPage={itemsPerPage}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                                handleFilterChange={handleFilterChange}
                                availableSubTopics={availableSubTopics}
                                serviceLabels={serviceLabels}
                                availableProfiles={availableProfiles}
                                profileLabels={profileLabels}
                                selectedServices={selectedServices}
                                selectedProfiles={selectedProfiles}
                                hasDistanceData={hasDistanceData}
                                paginatedData={paginatedData}
                                handleClinicClick={handleClinicClick}
                                showNoResults={clinicData?.length === 0}
                                servicesMetadata={servicesMetadata}
                                profilesMetadata={profilesMetadata}
                            />
                        )}

                        <Footnotes
                            footnotes={footNotes}
                            paginatedData={paginatedData}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default ArtClinics;