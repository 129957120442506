// stateUtils.js
class StateMapping {
    constructor() {
        this.stateMap = new Map();
        this.reverseStateMap = new Map();
    }

    initialize(territories) {
        if (!Array.isArray(territories)) {
            console.error('Expected an array for initialization');
            return;
        }
        this.stateMap.clear();
        this.reverseStateMap.clear();
        territories.forEach(({ state, stateAbbr }) => {
            this.stateMap.set(state, stateAbbr);
            this.reverseStateMap.set(stateAbbr, state);
        });
    }

    getStateAbbreviation(fullName) {
        return this.stateMap.get(fullName) || fullName;
    }

    getStateFullName(abbreviation) {
        return this.reverseStateMap.get(abbreviation) || abbreviation;
    }

    getAllStates() {
        return Array.from(this.stateMap.entries()).map(([fullName, code]) => ({
            fullName,
            code,
        }));
    }
}

export const stateMapping = new StateMapping();
export const getStateAbbreviation = (fullName) => stateMapping.getStateAbbreviation(fullName);
export const getStateFullName = (abbreviation) => stateMapping.getStateFullName(abbreviation);