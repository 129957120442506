//Component for Modify Search area for State/Us Territories, Zip and Distance
import React from 'react';
import PropTypes from 'prop-types';
import './SearchForm.scss';

const SearchForm = ({
    validationError,
    selectedState,
    zip,
    distance,
    stateTerritories, // Changed from stateMapping
    isSearchEnabled,
    handleStateChange,
    handleZipChange,
    handleDistanceChange,
    handleSearch,
    handleClear
}) => {
    return (
        <>
            <h2 className="text-primary">Modify Search:</h2>
            <div className="search-container">
                {validationError && (
                    <div className="alert alert-danger" role="alert">
                        {validationError}
                    </div>
                )}
                <div className="search-controls">
                    <div className="control-group">
                        <label className="control-label" htmlFor="stateterr">State/US territories</label>
                        <select
                            id="stateterr"
                            className="control-input stateDropdown"
                            value={selectedState}
                            onChange={handleStateChange}
                            aria-label="State or territory selection"
                        >
                            <option value="">Select a state or territory</option>
                            {/* States Group */}
                            <optgroup label="States" className="bg-primary">
                                {stateTerritories.states.map((state) => (
                                    <option key={state.stateCode} value={state.stateAbbr}>
                                        {state.state}
                                    </option>
                                ))}
                            </optgroup>

                            {/* Territories Group */}
                            <optgroup label="US Territories" className="bg-primary">
                                {stateTerritories.territories.map((territory) => (
                                    <option key={territory.stateCode} value={territory.stateAbbr}>
                                        {territory.state}
                                    </option>
                                ))}
                            </optgroup>
                        </select>
                    </div>

                    <div className="or-divider">OR</div>

                    <div className="control-group">
                        <label className="control-label" htmlFor="zip">Zip Code</label>
                        <input
                            id="zip"
                            type="text"
                            className="control-input"
                            value={zip}
                            onChange={handleZipChange}
                            placeholder="Enter zip code"
                            maxLength="5"
                        />
                    </div>

                    {(zip || distance) && (
                        <div className="control-group">
                            <label className="control-label" htmlFor="distance">Within</label>
                            <select
                                id="distance"
                                className="control-input"
                                value={distance}
                                onChange={handleDistanceChange}
                            >
                                <option value="5">5 Miles</option>
                                <option value="10">10 Miles</option>
                                <option value="20">20 Miles</option>
                                <option value="50">50 Miles</option>
                                <option value="100">100 Miles</option>
                            </select>
                        </div>
                    )}

                    <div className="button-group">
                        <button
                            className="search-button btn btn-primary"
                            onClick={handleSearch}
                            disabled={!isSearchEnabled()}
                        >
                            Search
                        </button>
                        <button className="btn b-outline-primary" onClick={handleClear}>
                            Clear
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

SearchForm.propTypes = {
    validationError: PropTypes.string,
    selectedState: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    distance: PropTypes.string.isRequired,
    stateTerritories: PropTypes.shape({
        states: PropTypes.arrayOf(PropTypes.shape({
            stateCode: PropTypes.string.isRequired,
            state: PropTypes.string.isRequired,
            displayOrder: PropTypes.string.isRequired
        })),
        territories: PropTypes.arrayOf(PropTypes.shape({
            stateCode: PropTypes.string.isRequired,
            state: PropTypes.string.isRequired,
            displayOrder: PropTypes.string.isRequired
        }))
    }).isRequired,
    isSearchEnabled: PropTypes.func.isRequired,
    handleStateChange: PropTypes.func.isRequired,
    handleZipChange: PropTypes.func.isRequired,
    handleDistanceChange: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    handleClear: PropTypes.func.isRequired
};

export default SearchForm;