import React, { Component } from "react";
import * as d3 from "d3";
import { returnDecimalNum } from "../../util";

class BarChart extends Component {
    constructor(props) {
        super(props);
        const clinicId = sessionStorage.getItem('ClinicId');
        this.state = {
            data: this.props.data,
            dataNational: this.props.dataNational,
            showNational: clinicId === '9999' ? false : (this.props.showNational || false),
            questionText: this.props.selectedText,
            chartTitle: this.props.chartTitle || 'Bar Chart'
        };
        this.ref = React.createRef();
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showNational !== this.props.showNational) {
            const clinicId = sessionStorage.getItem('ClinicId');
            this.setState({
                showNational: clinicId === '9999' ? false : this.props.showNational
            }, () => {
                d3.select(this.ref.current).selectAll("*").remove();
                this.init();
            });
        }
    }

    init() {
        const report = this.state.data;
        const questionText = this.state.questionText;
        const reportNational = this.state.dataNational;
        const showNational = this.state.showNational;
        const barWidth = showNational ? 30 : 70;
        const barSpacing = showNational ? 10 : 0;
        var svg = this.ref.current;

        // Calculate center positions for the bars
        const getBarCenter = (index) => {
            const baseX = 38 + 145 * index;
            if (this.state.showNational) {
                // When showing national data, we have two bars per group
                return baseX + ((30 + 10) / 2); // (barWidth + barSpacing) / 2
            }
            // When showing only clinic data
            return baseX + (45 / 2); // barWidth / 2
        };

        // Helper function for tooltip handling
        const handleTooltip = (event, d, isNational = false) => {
            const currentBar = d3.select(event.currentTarget);
            const i = parseInt(currentBar.attr("data-index"));
            const barX = parseInt(currentBar.attr("x"));

            // Only proceed if we're showing both bars (national is enabled)
            if (showNational) {
                // Use d3.select() properly for all selections
                const allSeries = d3.select(event.currentTarget.parentNode.parentNode)
                    .selectAll('.highcharts-series');

                const clinicBar = allSeries.nodes()[0] ?
                    d3.select(allSeries.nodes()[0]).select(`rect[data-index="${i}"]`) : null;

                const nationalBar = allSeries.nodes()[1] ?
                    d3.select(allSeries.nodes()[1]).select(`rect[data-index="${i}"]`) : null;

                // If we have both bars, find the higher one (smaller Y value)
                if (clinicBar && nationalBar && !clinicBar.empty() && !nationalBar.empty()) {
                    const clinicY = parseInt(clinicBar.attr("y"));
                    const nationalY = parseInt(nationalBar.attr("y"));

                    // Use Y position of the higher bar
                    const tooltipY = Math.min(clinicY, nationalY) + 40;  // Small offset from top of higher bar

                    currentBar.style("opacity", "0.8");

                    d3.select("div.highcharts-tooltip")
                        .style("left", `${barX + (isNational ? 220 : 270)}px`)
                        .style("top", `${tooltipY}px`)
                        .style("z-index", 1000);
                }
            } else {
                // If only showing clinic data, use simple positioning
                const barY = parseInt(currentBar.attr("y"));
                const tooltipY = barY + 40;

                currentBar.style("opacity", "0.8");

                d3.select("div.highcharts-tooltip")
                    .style("left", `${barX + 270}px`)
                    .style("top", `${tooltipY}px`)
                    .style("z-index", 1000);
            }

            const rowHeaders = isNational ? reportNational.RowHeaders : report.RowHeaders;
            d3.select("#tooltipText").html(
                `${isNational ? "National " : ""}Age of Patient ${rowHeaders[i + 1]}: ${Number(d).toFixed(1)}`
            );
        };

        svg = d3
            .select(svg)
            .attr("version", "1.1")
            .attr(
                "style",
                "font-family: Arial, Helvetica, sans-serif; font-size: 12px;"
            );

        svg
            .append("defs")
            .append("clipPath")
            .append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", 583)
            .attr("height", 291);

        svg
            .append("rect")
            .attr("x", 0.5)
            .attr("y", 0.5)
            .attr("width", 666)
            .attr("height", 398)
            .attr("strokeWidth", 1)
            .attr("rx", 4)
            .attr("ry", 4)
            .attr("fill", "#FFFFFF")
            .attr("stroke", "rgba(255, 255, 255, 0)")
            .attr("class", "highcharts-background");

        svg
            .append("rect")
            .attr("x", 75)
            .attr("y", 10)
            .attr("width", 583)
            .attr("height", 291)
            .attr("fill", "#FFFFFF")
            .attr("stroke", "rgba(255, 255, 255, 0)")
            .attr("class", "highcharts-background");

        svg.append("desc").text(`Bar chart showing ${questionText} by Age of patient`);

        // Grid lines
        svg
            .append("g")
            .attr("class", "highcharts-grid")
            .attr("zindex", 1)
            .selectAll("path")
            .data([366.5, 511.5, 658.5, 220.5, 74.5])
            .enter()
            .append("path")
            .attr("fill", "none")
            .attr("d", function (d) {
                return `M ${d} 10 L ${d} 301`;
            })
            .attr("stroke", "#FFFFFF")
            .attr("strokeWidth", 3)
            .attr("zindex", 1)
            .attr("opacity", 1);

        svg
            .append("g")
            .attr("class", "highcharts-grid")
            .attr("zindex", 1)
            .selectAll("path")
            .data([9.5, 68.5, 126.5, 185.5, 243.5, 301.5])
            .enter()
            .append("path")
            .attr("fill", "none")
            .attr("d", function (d) {
                return `M 75 ${d} L 658 ${d}`;
            })
            .attr("stroke", "#C0C0C0")
            .attr("strokeWidth", 1)
            .attr("zindex", 1)
            .attr("opacity", 1)
            .attr("transform", "translate(0, 50)");

        // Axes
        var axisX = svg
            .append("g")
            .attr("class", "highcharts-axis")
            .attr("zindex", 2);

        axisX
            .append("text")
            .attr("x", 320)
            .attr("y", 343)
            .attr("zindex", 7)
            .attr("text-anchor", "middle")
            .attr("transform", "translate(0, 50)")
            .attr("class", "highcharts-xaxis-title")
            .attr(
                "style",
                "color: #333333; font-weight: bold; font-size: 14px; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("visibility", "visible")
            .append("tspan")
            .text("Age of Patient");

        var axisY = svg
            .append("g")
            .attr("class", "highcharts-axis")
            .attr("zindex", 2);

        axisY
            .append("text")
            .attr("x", 26.427734375)
            .attr("y", 155.5)
            .attr("zindex", 7)
            .attr("text-anchor", "middle")
            .attr("transform", "translate(0, 50) rotate(270 26.427734375 155.5)")
            .attr("class", "highcharts-yaxis-title")
            .attr(
                "style",
                "color: #333333; font-weight: bold; font-size: 14px;"
            )
            .attr("visibility", "visible")
            .append("tspan")
            .text("Percent (%)");

        var series = svg
            .append("g")
            .attr("class", "highcharts-series-group")
            .attr("zindex", 3);

        // Clinic data bars
        series
            .append("g")
            .attr("class", "highcharts-series highcharts-tracker ")
            .attr("visibility", "visible")
            .attr("zindex", 0.1)
            .attr("transform", "translate(80, 58.5) scale(1 1)")
            .selectAll("rect")
            .data([
                returnDecimalNum(report.DataRows[0][1].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[0][2].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[0][3].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[0][4].replace("%", "") * 1.0)
            ])
            .enter()
            .append("rect")
            .attr("data-index", (d, i) => i)
            .attr("x", (d, index) => 38 + 145 * index)
            .attr("y", d => {
                // Clinic bars
                const calculatedHeight = parseInt((292 * d) / 100);
                const height = d > 0 ? Math.max(calculatedHeight, 2) : 0;
                return 292 - height;
            })
            .attr("width", barWidth)
            .attr("height", d => {
                const calculatedHeight = parseInt((292 * d) / 100);
                return d > 0 ? Math.max(calculatedHeight, 2) : 0; // Minimum 2px if value exists
            })
            .attr("fill", "#005ea2")
            .attr("rx", 0)
            .attr("ry", 0)
            .attr("stroke", "#FFFFFF")
            .attr("strokeWidth", 2)
            .on("mouseover", (event, d) => handleTooltip(event, d, false))
            .on("mouseout", (event) => {
                d3.select(event.currentTarget)
                    .style("opacity", "1");
                d3.select("div.highcharts-tooltip")
                    .style("left", "387px")
                    .style("top", "-9999px");
            });

        // National data bars
        if (showNational) {
            series
                .append("g")
                .attr("class", "highcharts-series highcharts-tracker")
                .attr("visibility", "visible")
                .attr("zindex", 0.1)
                .attr("transform", "translate(70, 58.5) scale(1 1)")
                .selectAll("rect")
                .data([
                    returnDecimalNum(reportNational.DataRows[0][1].replace("%", "") * 1.0),
                    returnDecimalNum(reportNational.DataRows[0][2].replace("%", "") * 1.0),
                    returnDecimalNum(reportNational.DataRows[0][3].replace("%", "") * 1.0),
                    returnDecimalNum(reportNational.DataRows[0][4].replace("%", "") * 1.0)
                ])
                .enter()
                .append("rect")
                .attr("data-index", (d, i) => i)
                .attr("x", (d, index) => 38 + barWidth + barSpacing + 145 * index)
                .attr("y", d => {
                    const calculatedHeight = parseInt((292 * d) / 100);
                    const actualHeight = d > 0 ? Math.max(calculatedHeight, 2) : 0;
                    return 292 - actualHeight;
                })
                .attr("width", barWidth)
                .attr("height", d => {
                    const calculatedHeight = parseInt((292 * d) / 100);
                    return d > 0 ? Math.max(calculatedHeight, 2) : 0; // Minimum 2px if value exists
                })
                .attr("fill", "#73b3e7")
                .attr("rx", 0)
                .attr("ry", 0)
                .attr("stroke", "#FFFFFF")
                .attr("strokeWidth", 2)
                .on("mouseover", (event, d) => handleTooltip(event, d, true))
                .on("mouseout", (event) => {
                    d3.select(event.currentTarget)
                        .style("opacity", "1");
                    d3.select("div.highcharts-tooltip")
                        .style("left", "387px")
                        .style("top", "-9999px");
                });
        }
        // Legend (only show national legend if showNational is true)
        var legend = svg
            .append("g")
            .attr("class", "highcharts-legend")
            .attr("zindex", 7)
            .attr("transform", "translate(301, 416)")
            .append("g")
            .attr("zindex", 1);

        // Clinic legend item
        var clinicItem = legend
            .append("g")
            .attr("class", "highcharts-legend-item")
            .attr("zindex", 1)
            .attr("transform", showNational ? "translate(-50, -15)" : "translate(0, -15)");

        clinicItem
            .append("text")
            .attr("x", 21)
            .attr("y", 17)
            .attr("style", "color: Black; font-size: 14px; font-weight: bold; font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif; fill: Black;")
            .attr("text-anchor", "start")
            .text(sessionStorage.getItem('ClinicId') === '9999' ? "National" : "Clinic");

        clinicItem
            .append("rect")
            .attr("x", 0)
            .attr("y", 6)
            .attr("width", 16)
            .attr("height", 12)
            .attr("fill", "#005ea2");

        // National legend item (only if showNational is true)
        if (showNational) {
            var nationalItem = legend
                .append("g")
                .attr("class", "highcharts-legend-item")
                .attr("zindex", 1)
                .attr("transform", "translate(50, -15)");

            nationalItem
                .append("text")
                .attr("x", 21)
                .attr("y", 17)
                .attr("style", "color: Black; font-size: 14px; font-weight: bold; font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif; fill: Black;")
                .attr("text-anchor", "start")
                .text("National");

            nationalItem
                .append("rect")
                .attr("x", 0)
                .attr("y", 6)
                .attr("width", 16)
                .attr("height", 12)
                .attr("fill", "#73b3e7");
        }

        // X-axis labels
        svg
            .append("g")
            .attr("class", "highcharts-axis-labels highcharts-xaxis-labels one")
            .attr("zindex", 7)
            .selectAll("text")
            .data([
                report.RowHeaders[1],
                report.RowHeaders[2],
                report.RowHeaders[3],
                report.RowHeaders[4]
            ])
            .enter()
            .append("text")
            .attr("x", (d, index) => {
                // Center the label with the bar group
                return getBarCenter(index) + 90; // Added offset to align with bars
            })
            .attr("y", 323)
            .attr("text-anchor", "middle")
            .attr("transform", "translate(0, 50)")
            .attr(
                "style",
                "color: #333333; cursor: default; font-size: 14px; font-weight: normal; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("opacity", 1)
            .text(function (d) {
                return d;
            });

        // Y-axis labels
        svg
            .append("g")
            .attr("class", "highcharts-axis-labels highcharts-yaxis-labels")
            .attr("zindex", 7)
            .selectAll("text")
            .data([0, 20, 40, 60, 80, 100])
            .enter()
            .append("text")
            .attr("x", 60)
            .attr("y", function (d, index) {
                return 307 - index * 58.2;
            })
            .attr("text-anchor", "end")
            .attr("transform", "translate(0, 50)")
            .attr(
                "style",
                "width: 200px; color: #333333; cursor: default; font-size: 14px; font-weight: normal; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("opacity", 1)
            .text(function (d) {
                return d;
            });
    }

    render() {
        const chartTitle = this.state.chartTitle || 'Vertical Bar Chart';

        return <svg className="barChartVertical" width="668" height="460" ref={this.ref} role="img" aria-labelledby={chartTitle}></svg>;
    }
}

export default BarChart;