// Helper function to process metadata and create service/profile mappings
const processMetadata = (metadata) => {
    // Initialize containers for our mappings
    const subTopics = [];
    const serviceLabelsMap = {};
    const profiles = [];
    const profileLabelsMap = {};

    metadata.forEach(([label, description, year, id]) => {
        // Services have IDs starting with 'ST' and are less than ST14
        if (id.startsWith('ST') && parseInt(id.slice(2)) < 14) {
            subTopics.push(id);
            serviceLabelsMap[id] = label;
        }
        // Profiles are ST14 and above, or special cases like Q334
        else {
            profiles.push(id);
            profileLabelsMap[id] = label;
        }
    });

    return {
        availableSubTopics: subTopics,
        serviceLabels: serviceLabelsMap,
        availableProfiles: profiles,
        profileLabels: profileLabelsMap
    };
};

// Export the function that will process the metadata
export const createServiceMappings = (metadata) => {
    const {
        availableSubTopics,
        serviceLabels,
        availableProfiles,
        profileLabels
    } = processMetadata(metadata);

    // Return all the mappings
    return {
        availableSubTopics,
        serviceLabels,
        availableProfiles,
        profileLabels
    };
};

// Default export for backward compatibility
export default createServiceMappings;
