import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import * as actions from '../redux/actions';
import html2canvas from "html2canvas";
import Tab0 from "./tabs/Tab0";
import Tab1 from "./tabs/Tab1";
import Tab2 from "./tabs/Tab2";
import Tab3 from "./tabs/Tab3";
import Tab4 from "./tabs/Tab4";
import { GetEnvironment, IsArtDashboard } from '../api'
import CDCbluePDFFooterIconBase64Url from "./CDCbluePDFFooterIconBase64Url"
import { jwtDecode as jwt_decode } from 'jwt-decode';
import { NoData } from "./NoData";
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner'
import NavigationHeader from "./NavigationHeader";
import RelatedLinks from "./RelatedLinks";
import { Helmet } from 'react-helmet';
import { useSetTitle } from "../Utility";
import { trigger_interaction, trigger_pageview } from '../Utility'



const TabPage = ({ user, actions }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [currentClinicId, setCurrentClinicId] = useState(null);
    const [isNationalData, setNationalData] = useState(false);
    const [reportingYear, setReportingYear] = useState('');



    const getFetchHeaders = (isArtDashboard) => {
        return {
            'X-Database-Type': isArtDashboard ? 'art' : 'nass',
            'Content-Type': 'application/json'
        };
    };

    const navigate = useNavigate();
    var today = new Date();
    const isArtDashBoard = IsArtDashboard();
    let pageHeading = isArtDashBoard ? `View ART Data for Reporting Year ${reportingYear}` : "Your Clinic's ART Data";
    let pageTitle = isArtDashBoard ? "Assisted Reproductive Technology (ART) Data" : "NASS Clinic Dashboard";

    let getClinicIdFromSession = sessionStorage.getItem('ClinicId'),
        _isNationalData = getClinicIdFromSession === "9999" ? true : false;


    // Function to fetch all data
    const fetchAllData = (clinicId, year) => {
        let fetchUriPrefix = process.env.REACT_APP_FETCH_URI_PREFIX;
        if (fetchUriPrefix == null) {
            fetchUriPrefix = '';
        }

        // Reset loading states
        tab0_setIsLoading(true);
        tab1_setIsLoading(true);
        tab2_setIsLoading(true);
        tab3_setIsLoading(true);
        tab4_setIsLoading(true);

        const headers = getFetchHeaders(isArtDashBoard);

        // Function to handle fetch errors
        const handleFetchError = (error, tabName) => {
            console.error(`Error fetching ${tabName} data:`, error);
            switch (tabName) {
                case 'ServicesAndProfiles':
                    tab0_setIsLoading(false);
                    tab0_setTables([]);
                    setHasData(false);
                    break;
                case 'PatientAndCycle':
                    tab1_setIsLoading(false);
                    tab1_setTables([]);
                    tab1_setIsLoadingNational(false);
                    tab1_setTablesNational([]);
                    break;
                case 'SuccessRateOwnEggs':
                    tab2_setIsLoading(false);
                    tab2_setTables([]);
                    tab2_setIsLoadingNational(false);
                    tab2_setTablesNational([]);
                    break;
                case 'SuccessRateDonorEggs':
                    tab3_setIsLoading(false);
                    tab3_setTables([]);
                    tab3_setIsLoadingNational(false);
                    tab3_setTablesNational([]);
                    break;
                case 'Summary':
                    tab4_setIsLoading(false);
                    tab4_setTables([]);
                    break;
                default:
                    break;
            }
        };

        //Get google map key
        fetch(`${fetchUriPrefix}/api/GetEnv/GetGoogleMapInfo`, {
            headers: headers
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((response) => {
                setGoogleMapKey(response.GoogleMapAPIKey)
            })
            .catch((error) => handleFetchError(error, 'GoogleMapInfo'));

        // Fetch Services And Profiles, Tab1
        fetch(`${fetchUriPrefix}/api/ServicesAndProfiles/years/${year}/clinics/${clinicId}/useArtDashboardSP/${isArtDashBoard}`, {
            headers: headers
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((response) => {
                tab0_setTables(response);
                setHasData(true);
                setReportingYear(response.ClinicInfo.ReportingYear);
                tab0_setIsLoading(false);
                //console.log('Tab 1 Info:', response);
                sessionStorage.setItem('medicalDirector', response.ClinicInfo.MedicalDirector)
            })
            .catch((error) => handleFetchError(error, 'ServicesAndProfiles'));

        // Fetch Patient And Cycle, Tab2
        fetch(`${fetchUriPrefix}/api/PatientAndCycle/years/${year}/clinics/${clinicId}/useArtDashboardSP/${isArtDashBoard}/nationalData/${_isNationalData}`,
            {
                headers: headers,
                signal: AbortSignal.timeout(300000)
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((response) => {
                setTimeout(() => {
                    tab1_setTables(response);
                    tab1_setIsLoading(false);
                    //console.log('Tab 2 Info:', response);
                }, 1500);
            })
            .catch((error) => handleFetchError(error, 'PatientAndCycle'));

        // Fetch Success Rate Own Eggs, Tab3
        fetch(`${fetchUriPrefix}/api/SuccessRateOwnEggs/years/${year}/clinics/${clinicId}/useArtDashboardSP/${isArtDashBoard}/nationalData/${_isNationalData}`,
            {
                headers: headers,
                signal: AbortSignal.timeout(300000)
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((response) => {
                setTimeout(() => {
                    tab2_setTables(response);
                    tab2_setIsLoading(false);
                    //console.log('Tab 3 Info:', response);
                }, 1500);
            })
            .catch((error) => handleFetchError(error, 'SuccessRateOwnEggs'));

        // Fetch Success Rate Donor Eggs, Tab4
        fetch(`${fetchUriPrefix}/api/SuccessRateDonorEggs/years/${year}/clinics/${clinicId}/useArtDashboardSP/${isArtDashBoard}`, {
            headers: headers
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((response) => {
                setTimeout(() => {
                    tab3_setTables(response);
                    tab3_setIsLoading(false);
                    //console.log('Tab 4 Info:', response);
                }, 1500);
            })
            .catch((error) => handleFetchError(error, 'SuccessRateDonorEggs'));

        // Fetch Summary, Tab5
        fetch(`${fetchUriPrefix}/api/Summary/years/${year}/clinics/${clinicId}/useArtDashboardSP/${isArtDashBoard}`, {
            headers: headers
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((response) => {
                setTimeout(() => {
                    tab4_setTables(response);
                    tab4_setIsLoading(false);
                    //console.log('Tab 5 Info:', response);
                }, 1500);
            })
            .catch((error) => handleFetchError(error, 'Summary'));
    };

    // Handle data type change (clinic vs national)
    const handleDataTypeChange = (newClinicId) => {
        setCurrentClinicId(newClinicId);
        const year = sessionStorage.getItem('Year') || "0";
        fetchAllData(newClinicId, year);
    };

    useEffect(() => {

        // ****LOADING ART DASHBOARD*****
        const handleArtDashboard = () => {
            const params = new URLSearchParams(window.location.search);
            const queryClinicId = params.get('clinicid');

            // Get clinicId from query params or sessionStorage
            let artClinicId = queryClinicId || sessionStorage.getItem('ClinicId');

            // Set national data flag
            artClinicId === "9999" ? setNationalData(true) : setNationalData(false);
            sessionStorage.setItem('Year', "0");//max year will be grab from DB

            // Handle clinic navigation
            if (artClinicId === "" || artClinicId === null) {
                navigate('/artclinics');
            } else {
                // Store clinicId in session if it came from query params
                if (queryClinicId) {
                    sessionStorage.setItem('ClinicId', queryClinicId);
                    if (queryClinicId === "9999") {
                        sessionStorage.setItem('NationalHeaderLinkClick', 'true')
                    } else {
                        //handle when single clinic from query
                        sessionStorage.setItem('ModifyClinicId', queryClinicId);
                        sessionStorage.setItem('NationalHeaderLinkClick', 'false')
                    }


                    // Clear URL query parameters
                    window.history.replaceState({}, '', window.location.pathname);
                }
                setCurrentClinicId(artClinicId);
                fetchAllData(artClinicId, "0");
            }
        }

        // NOTE: If you want to hit ArtDashboard locally go to api/index.js and return the method as true
        if (isArtDashBoard) {
            handleArtDashboard();
        } else {
            // *****Loading NASS CLINIC DASHBOARD*****
            let skipAuth = process.env.REACT_APP_AUTH_BYPASS === 'true',
                params = new URLSearchParams(window.location.search);

            let environment = GetEnvironment(),
                ClinicId = "",
                Year = "";

            //get and decode jwt token
            try {
                let jwtToken = params.get('ncdjwt'),
                    decodedToken = jwt_decode(jwtToken);

                //get clinicid, year and expiration 
                ClinicId = decodedToken.ClinicId;
                Year = decodedToken.Year;
                //Expiration = decodedToken.exp;
            } catch (e) {
                console.log(e)
            }

            //method to set clinicid and year to session. It was set to a cookie, but was causing issues so using session instead. If cookie is needed, uncomment the code. 
            function setCookieFromJWT() {
                if (ClinicId !== "" && Year !== "") {
                    sessionStorage.setItem('ClinicId', ClinicId)
                    sessionStorage.setItem('Year', Year)
                }
            }

            //if dev or local, allow direct access to the app with clinicid and year in the url
            if (environment === "dev" || environment === "localhost") {
                //bypassing sams ONLY in dev
                skipAuth = 'true';

                let _clinicid = params.get("clinicid"),
                    _year = params.get("year");

                //if no jwt token
                if (params.has('clinicid') === true && params.has('year') === true) { //using direct access
                    console.log("DEV SAMS Bypass: Query Parameters Found: ", _clinicid, _year)
                    sessionStorage.setItem('ClinicId', _clinicid)
                    sessionStorage.setItem('Year', _year)

                    //clear query string to prevent double reload, work locally but not in dev
                    navigate("/#")
                } else if (Year === undefined || Year === 'null' || Year === null || Year === "" || ClinicId === undefined || ClinicId === 'null' || ClinicId === null || ClinicId === "") {
                    console.log("DEV SAMS Bypass: No JWT Token or query parameters found...")
                    //_clinicid = "377";
                    _clinicid = "721";
                    _year = "2022";

                    sessionStorage.setItem('ClinicId', _clinicid)
                    sessionStorage.setItem('Year', _year)
                    console.log("DEV SAMS Bypass: Setting hardcoded clinicId and year.", _clinicid + ' ' + _year);

                } else {
                    console.log('Setting JWT Token')
                    setCookieFromJWT(); //using jwt
                    console.log('Setting JWT Token Completed:', sessionStorage.getItem('ClinicId'), sessionStorage.getItem('Year'))
                    //clear query string to prevent double reload, work locally but not in dev
                    navigate("/#")
                }
            } else { //all other environments will reject direct access to the app(int, prod)
                setCookieFromJWT()
            }

            //SAMS authenication for each envrionment
            if (!user.email) {
                if (!skipAuth) {
                    var SAMSCallbackURL = "",
                        SAMSClinicId = "";

                    if (environment === "dev") {
                        SAMSCallbackURL = "https://clinic-dashboard-ui-nass-clinic-dashboard.apps.ecpaas-dev.cdc.gov/samscallback";
                        SAMSClinicId = "bfab691b-91c3-4c1a-8ba4-3a6daf986a90";
                        window.location.replace("https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize?client_id=" + SAMSClinicId + "&redirect_uri=" + SAMSCallbackURL + "&response_type=code&scope=openid+profile+email");
                    }
                    if (environment === "qa") {
                        SAMSCallbackURL = "https://artreportingdashboard-test.cdc.gov/samscallback";
                        SAMSClinicId = "bfab691b-91c3-4c1a-8ba4-3a6daf986a90";
                        window.location.replace("https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize?client_id=" + SAMSClinicId + "&redirect_uri=" + SAMSCallbackURL + "&response_type=code&scope=openid+profile+email");
                    }
                    if (environment === "int") {
                        SAMSCallbackURL = "https://artreportingdashboard-int.cdc.gov/samscallback";
                        SAMSClinicId = "bfab691b-91c3-4c1a-8ba4-3a6daf986a90";
                        window.location.replace("https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize?client_id=" + SAMSClinicId + "&redirect_uri=" + SAMSCallbackURL + "&response_type=code&scope=openid+profile+email");
                    }
                    if (environment === "prod") {
                        SAMSCallbackURL = "https://artreportingdashboard.cdc.gov/samscallback";
                        SAMSClinicId = "7489f337-6620-4584-82d8-115b23e0ecad";
                        window.location.replace("https://apigw.cdc.gov/auth/oauth/v2/authorize?client_id=" + SAMSClinicId + "&redirect_uri=" + SAMSCallbackURL + "&response_type=code&scope=openid+profile+email");
                    }
                }

                //local host testing for sams
                //window.location.replace("https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize?client_id=bfab691b-91c3-4c1a-8ba4-3a6daf986a90&redirect_uri=http://localhost:3000/samscallback&response_type=code&scope=openid+profile+email");
            }
        }


    }, [user, actions]);

    const [hasData, setHasData] = useState(false);
    const [homePageLink, setHomePageLink] = useState("");
    const [fetchYear, setFetchYear] = useState(0);
    const [tab0_tables, tab0_setTables] = useState([]);
    const [tab0_isLoading, tab0_setIsLoading] = useState(true);
    const [tab1_tables, tab1_setTables] = useState([]);
    const [tab1_isLoading, tab1_setIsLoading] = useState(true);
    const [tab2_tables, tab2_setTables] = useState([]);
    const [tab2_isLoading, tab2_setIsLoading] = useState(true);
    const [tab3_tables, tab3_setTables] = useState([]);
    const [tab3_isLoading, tab3_setIsLoading] = useState(true);
    const [tab1_tablesNational, tab1_setTablesNational] = useState([]);
    const [tab1_isLoadingNational, tab1_setIsLoadingNational] = useState(true);
    const [tab2_tablesNational, tab2_setTablesNational] = useState([]);
    const [tab2_isLoadingNational, tab2_setIsLoadingNational] = useState(true);
    const [tab3_tablesNational, tab3_setTablesNational] = useState([]);
    const [tab3_isLoadingNational, tab3_setIsLoadingNational] = useState(true);
    const [tab4_tables, tab4_setTables] = useState([]);
    const [tab4_isLoading, tab4_setIsLoading] = useState(true);
    const [clinicStatus, setClinicStatus] = useState('');
    const [footnotes, setFootnotes] = useState([]);
    const [googleMapKey, setGoogleMapKey] = useState([]);

    AbortSignal.timeout ??= function timeout(ms) {
        const ctrl = new AbortController()
        setTimeout(() => ctrl.close(), ms)
        return ctrl.signal
    }

    useEffect(() => {
        tab0_setIsLoading(true);
        let year,
            clinicId;

        const headers = getFetchHeaders(isArtDashBoard);

        //SAMS bypass in dev, this was done to fix WI issues from SA team
        if (GetEnvironment() === 'dev' || GetEnvironment() === 'localhost') {
            clinicId = sessionStorage.getItem('ClinicId');
            year = sessionStorage.getItem('Year');
            console.log(isArtDashBoard ? "Setting ArtDashboard Bypass DEV/Local" : "Setting NASS Bypass DEV/Local");
        } else if (isArtDashBoard) { //if ART Dashboard don't grab the jwt token for year and clinic id
            console.log('Setting Art Dashboard Year and ClinicId');
            clinicId = sessionStorage.getItem('ClinicId');
            year = sessionStorage.getItem('Year');
        } else { //if NASS Clinic Dashboard, grab jwt token for year and clinicid
            console.log('Setting NASS Dashboard Year and ClinicId');
            year = user.year;
            clinicId = user.clinicid;
        }


        let fetchUriPrefix = process.env.REACT_APP_FETCH_URI_PREFIX
        if (fetchUriPrefix == null) {
            fetchUriPrefix = ''
        } else {
            if (GetEnvironment() !== 'localhost') {
                year = process.env.REACT_APP_FETCH_YEAR
                clinicId = process.env.REACT_APP_FETCH_CLINIC_ID
            }
        }
        setFetchYear(year)

        fetch(fetchUriPrefix + "/api/ServicesAndProfiles/years/" + year + "/clinics/" + clinicId + "/useArtDashboardSP/" + isArtDashBoard, {
            headers: headers
        })
            .then((response) => response.json())
            .then((response) => {
                const status = response.ClinicInfo.ClinicStatus;
                setClinicStatus(status); // Save clinic status to state

                fetch(`${fetchUriPrefix}/api/ServicesAndProfiles/footnotes?footnotes=${status}`, {
                    headers: headers
                })
                    .then(response => response.json())
                    .then(data => {
                        setFootnotes(data.Footnotes); // Save footnotes to state
                    })
                    .catch(error => console.error('Error:', error));

                tab0_setTables(response);
                setHasData(true);
                tab0_setIsLoading(false);
            });

        fetch(fetchUriPrefix + "/api/PatientAndCycle/years/" + year + "/clinics/" + clinicId + "/useArtDashboardSP/" + isArtDashBoard + "/nationalData/" + _isNationalData,
            {
                headers: headers,
                signal: AbortSignal.timeout(300000)
            })
            .then((response) => response.json())
            .then((response) => {
                setTimeout(() => {
                    //console.log('result tab2:', response, clinicId, year)
                    tab1_setTables(response);
                    tab1_setIsLoading(false);
                }, 1500) //delay to give time to load tab 2, without this, tab2 will bomb out. Will revisit reason at a later time. 
            });

        //tab 2 national clinic data to use for data visualization
        if (isArtDashBoard) {
            fetch(fetchUriPrefix + "/api/PatientAndCycle/years/" + year + "/clinics/" + 9999 + "/useArtDashboardSP/" + true + "/nationalData/" + _isNationalData,
                {
                    headers: headers,
                    signal: AbortSignal.timeout(300000)
                })
                .then((response) => response.json())
                .then((response) => {
                    setTimeout(() => {
                        //console.log('result tab2 national:', response, clinicId, year)
                        tab1_setTablesNational(response);
                        tab1_setIsLoadingNational(false);
                    }, 1500) //delay to give time to load tab 2, without this, tab2 will bomb out. Will revisit reason at a later time. 
                });

        }
        fetch(fetchUriPrefix + "/api/SuccessRateOwnEggs/years/" + year + "/clinics/" + clinicId + "/useArtDashboardSP/" + isArtDashBoard + "/nationalData/" + _isNationalData,
            {
                headers: headers,
                signal: AbortSignal.timeout(300000)
            }) //5minutes in ms
            .then((response) => response.json())
            .then((response) => {
                setTimeout(() => {
                    //console.log('result tab3:', response, clinicId, year)
                    tab2_setTables(response);
                    tab2_setIsLoading(false);
                }, 1500)
            });

        //tab 3 national data to use for data visualization
        if (isArtDashBoard) {
            fetch(fetchUriPrefix + "/api/SuccessRateOwnEggs/years/" + year + "/clinics/" + 9999 + "/useArtDashboardSP/" + true + "/nationalData/" + _isNationalData,
                {
                    headers: headers,
                    signal: AbortSignal.timeout(300000)
                }) //5minutes in ms
                .then((response) => response.json())
                .then((response) => {
                    setTimeout(() => {
                        //console.log('result tab3 national:', response, clinicId, year)
                        tab2_setTablesNational(response);
                        tab2_setIsLoadingNational(false);
                    }, 1500)
                });
        }

        fetch(fetchUriPrefix + "/api/SuccessRateDonorEggs/years/" + year + "/clinics/" + clinicId + "/useArtDashboardSP/" + isArtDashBoard, {
            headers: headers
        })
            .then((response) => response.json())
            .then((response) => {
                //console.log('result tab4:', response, clinicId, year)
                setTimeout(() => {
                    tab3_setTables(response);
                    tab3_setIsLoading(false);
                }, 1500)
            });

        //tab 4 national data to use for data visualization
        if (isArtDashBoard) {
            fetch(fetchUriPrefix + "/api/SuccessRateDonorEggs/years/" + year + "/clinics/" + 9999 + "/useArtDashboardSP/" + true, {
                headers: headers
            })
                .then((response) => response.json())
                .then((response) => {
                    //console.log('result tab4 national:', response, clinicId, year)
                    setTimeout(() => {
                        tab3_setTablesNational(response);
                        tab3_setIsLoadingNational(false);
                    }, 1500)
                });
        }

        if (isArtDashBoard) {
            fetch(fetchUriPrefix + "/api/SuccessRateDonorEggs/years/" + year + "/clinics/" + 9999 + "/useArtDashboardSP/" + true, {
                headers: headers
            })
                .then((response) => response.json())
                .then((response) => {
                    //console.log('result tab4 national:', response, clinicId, year)
                    setTimeout(() => {
                        tab3_setTablesNational(response);
                        tab3_setIsLoadingNational(false);
                    }, 1500)
                });
        }

        //tab 5
        fetch(fetchUriPrefix + "/api/Summary/years/" + year + "/clinics/" + clinicId + "/useArtDashboardSP/" + isArtDashBoard, {
            headers: headers
        })
            .then((response) => response.json())
            .then((response) => {
                setTimeout(() => {
                    //console.log('result tab5:', response, clinicId, year)
                    tab4_setTables(response);
                    tab4_setIsLoading(false);
                }, 1500);
            });

        //redirect link back to art reporting
        fetch(fetchUriPrefix + "/api/GetEnv/GetEnvConfigInfo",
            {
                headers: headers,
            })
            .then((response) => response.json())
            .then((response) => {
                let environment = GetEnvironment(),
                    artReportingHomePageLink = "";


                switch (environment) {
                    case "dev":
                        artReportingHomePageLink = "https://artreportingdev.cdc.gov/Project/Clinic/DashboardCallBack.aspx?year=" + year;
                        break;
                    case "qa":
                        artReportingHomePageLink = "https://artreportingtest.cdc.gov/Project/Clinic/DashboardCallBack.aspx?year=" + year;
                        break;
                    case "int":
                        artReportingHomePageLink = "https://artreportingtest.cdc.gov/Project/Clinic/DashboardCallBack.aspx?year=" + year;
                        break;
                    case "prod": //is soft prod or regular prod
                        if (response.IsSoftProd) {
                            artReportingHomePageLink = "https://artreportingprod.cdc.gov/Project/Clinic/DashboardCallBack.aspx?year=" + year;
                        } else {
                            artReportingHomePageLink = "https://artreporting.cdc.gov/Project/Clinic/DashboardCallBack.aspx?year=" + year
                        }
                        break;
                    default:
                        artReportingHomePageLink = "https://artreportingdev.cdc.gov/Project/Clinic/DashboardCallBack.aspx?year=" + year;
                    // default code block
                }
                setHomePageLink(artReportingHomePageLink)

            });
    }, [user.clinicid, user.year]);


    const tabTitles = [
        "Clinic Services & Profile",
        "Patient & Cycle Characteristics",
        "Success Rates: Patients Using Own Eggs",
        "Success Rates: Patients Using Donor Eggs",
        "Clinic Data Summary",
    ];

    useSetTitle(tabTitles[tabIndex], isArtDashBoard);
    function changeTabHandler(index) {
        setTabIndex(index)
    }

    const getImgElement = async (input, imgWidth = 900, altText) => {
        var img,
            _tabIndex = tabIndex;
        await html2canvas(input, {
            onclone: (document) => {
                // console.log(input)
            },
        }).then((canvas) => {
            const image = { type: "jpeg", quality: 0.98 };
            let _imgWidth = '900';

            // Calculate the number of pages.
            var pxFullHeight = canvas.height;

            // Create a one-page canvas to split up the full image.
            var pageCanvas = document.createElement("canvas");
            var pageCtx = pageCanvas.getContext("2d");
            pageCanvas.width = canvas.width;
            pageCanvas.height = pxFullHeight; //pxPageHeight;

            var page = 0;
            pageCanvas.height = pxFullHeight; // % pxPageHeight;

            // Display the page.
            var w = pageCanvas.width;
            var h = pageCanvas.height;
            pageCtx.fillStyle = "white";
            pageCtx.fillRect(0, 0, w, h);
            pageCtx.drawImage(canvas, 0, page * pxFullHeight, w, h, 0, 0, w, h); // was page * pxPageHeight

            var imgData = pageCanvas.toDataURL(
                "image/" + image.type,
                image.quality
            );

            img = document.createElement('img');
            img.setAttribute("alt", altText);
            img.src = imgData;

            // Add style attributes to control spacing
            img.style.margin = "0";
            img.style.padding = "0";
            img.style.display = "block"; // Prevents inline spacing

            // Set proper width based on tab
            switch (true) {
                case _tabIndex === 3:
                    _imgWidth = '800';
                    break;
                case _tabIndex === 4:
                    _imgWidth = imgWidth;
                    break;
                case _tabIndex === 1: // Add special handling for tab 1
                    _imgWidth = '900';
                    img.style.pageBreakInside = 'avoid';
                    img.style.breakInside = 'avoid';
                    break;
                default:
                    _imgWidth = '900';
            }
            img.width = _imgWidth;
        });
        return img;
    }

    const saveToPDFHandler = async () => {
        console.log('saveToPDFHandler: starting...')

        // start spinner
        let fetchUriPrefix = process.env.REACT_APP_FETCH_URI_PREFIX
        if (fetchUriPrefix == null) {
            fetchUriPrefix = ''
        }

        var parentDiv = document.createElement("div"),
            pdfImgAltText = "";
        const titleARTData = document.createElement("div")

        //update pdf main heading with tab labels
        const tabMainHeading = "NASS Clinic Dashboard: "
        switch (true) {
            case tabIndex === 0:
                titleARTData.innerText = tabMainHeading + document.getElementById("linkTab0").innerText
                pdfImgAltText = document.querySelectorAll('#tabs-nav li')[0].innerText
                break;
            case tabIndex === 1:
                titleARTData.innerText = tabMainHeading + document.getElementById("linkTab1").innerText
                pdfImgAltText = document.querySelectorAll('#tabs-nav li')[1].innerText
                break;
            case tabIndex === 2:
                titleARTData.innerText = tabMainHeading + document.getElementById("linkTab2").innerText
                pdfImgAltText = document.querySelectorAll('#tabs-nav li')[2].innerText
                break;
            case tabIndex === 3:
                titleARTData.innerText = tabMainHeading + document.getElementById("linkTab3").innerText
                pdfImgAltText = document.querySelectorAll('#tabs-nav li')[3].innerText
                break;
            case tabIndex === 4:
                titleARTData.innerText = tabMainHeading + document.getElementById("linkTab4").innerText
                pdfImgAltText = document.querySelectorAll('#tabs-nav li')[4].innerText
                break;
            default:
            // code block
        }

        titleARTData.style.cssText = "margin-left:40px;font-family:arial;font-weight:bold;font-size:1.3rem;margin-bottom:1rem;margin-top:1.5rem"
        parentDiv.appendChild(titleARTData)

        const inputFacility = document.getElementById("pdfDivFacilityName");
        const imgFaciility = await getImgElement(inputFacility, 900, "Facility Name")
        imgFaciility.style.cssText = "margin-left:40px;margin-bottom:1rem"
        parentDiv.appendChild(imgFaciility)


        //tab description
        if (tabIndex === 1 || tabIndex === 2 || tabIndex === 3 || tabIndex === 4) {
            const tab1TableHeaderPara = document.getElementById("tab2Title1");
            const tab1TableHeader = document.createElement("div")
            //tab1TableHeader.innerText = tab1TableHeaderPara.innerHTML.replace("<br><br>", "\n\n")
            tab1TableHeader.innerText = tab1TableHeaderPara.innerHTML.replaceAll("<br>", "\n")
            tab1TableHeader.style.cssText = "font-family:arial;font-size:1.1rem;margin-bottom:1rem;margin-top:1rem;margin-left:40px;margin-right:60px"
            parentDiv.appendChild(tab1TableHeader)
        }

        //selection 
        if (tabIndex === 1 || tabIndex === 2 || tabIndex === 3) {
            const tab1QuestionSelect = document.getElementById("tab2Question");
            const tab1Question = document.createElement("div")
            tab1Question.innerText = tab1QuestionSelect.options[tab1QuestionSelect.value].text
            tab1Question.style.cssText = "font-family:arial;font-weight:bold;font-size:1.3rem;margin-bottom:1rem;margin-top:1rem;margin-left:40px;margin-right:60px"
            parentDiv.appendChild(tab1Question)
        }

        //selection description
        if (tabIndex === 1 || tabIndex === 2 || tabIndex === 3) {
            const tab1DescriptionDiv = document.getElementById("tab2Title2");
            const tab1Description = document.createElement("div")
            tab1Description.innerText = tab1DescriptionDiv.innerHTML
            tab1Description.style.cssText = "font-family:arial;font-size:1.1rem;margin-bottom:1rem;margin-top:1rem;margin-left:40px;margin-right:60px"
            parentDiv.appendChild(tab1Description)
        }

        if (tabIndex === 4) {
            const inputTabTbl2 = document.getElementById("pdfDivTbl2"),
                imgTab2 = await getImgElement(inputTabTbl2, "900", "Cumulative Art Success Rates for Intended Retrievals Among Patients Using Their Own Eggs:New patients (with no prior ART cycles)"),
                inputTabTbl3 = document.getElementById("pdfDivTbl3"),

                imgTab3 = await getImgElement(inputTabTbl3, "900", "Non-cumulative Art Success Rates for Transfers Among Patients Using Eggs or Embryos from a Donor a,b,c,d and Characteristics of ART Cycles a,b"),
                inputTabTbl4 = document.getElementById("pdfDivTbl4"),

                imgTab4 = await getImgElement(inputTabTbl4, "900", "Clinic Services Summary and Reason for Using ARTa,b,g"),
                inputTabTbl5 = document.getElementById("legendTextWrapper"),

                imgTab5 = await getImgElement(inputTabTbl5, "900", "Legend"),
                imgTabDiv1 = document.createElement("div"),

                imgTabDiv3 = document.createElement("div"),
                imgTabDiv4 = document.createElement("div"),
                imgTabDiv5 = document.createElement("div");

            imgTabDiv1.style.cssText = "vertical-align:middle;text-align:center; margin-right: 22px;"
            imgTabDiv1.appendChild(imgTab2)
            parentDiv.appendChild(imgTabDiv1)

            imgTabDiv3.style.cssText = "vertical-align:middle;text-align:center; margin-right: 22px; margin-top: 15em;"
            imgTabDiv3.appendChild(imgTab3)
            parentDiv.appendChild(imgTabDiv3)

            imgTabDiv4.style.cssText = "vertical-align:middle;text-align:center;margin-right: 22px; margin-top: 35em;"
            imgTabDiv4.appendChild(imgTab4)
            parentDiv.appendChild(imgTabDiv4)

            imgTabDiv5.style.cssText = "vertical-align:middle; margin-left: 40px;"
            imgTabDiv5.appendChild(imgTab5)
            parentDiv.appendChild(imgTabDiv5)

        } else if (tabIndex === 3) {
            const inputTab = document.getElementById("pdfDiv");
            const imgTab = await getImgElement(inputTab, "800", pdfImgAltText);
            let marginBottom = document.getElementById('tab2Title2').innerText.length > 200 && "margin-bottom: 5em;";
            const imgTabDiv = document.createElement("div")
            imgTabDiv.style.cssText = "vertical-align:middle;text-align:center;" + marginBottom;
            imgTabDiv.appendChild(imgTab)
            parentDiv.appendChild(imgTabDiv)
        } else {
            const inputTab = document.getElementById("pdfDiv");
            const imgTab = await getImgElement(inputTab, "900", pdfImgAltText)
            const imgTabDiv = document.createElement("div")
            imgTabDiv.style.cssText = "margin-left:40px;"
            imgTabDiv.appendChild(imgTab)
            parentDiv.appendChild(imgTabDiv)
        }

        const imgFooter = document.createElement("img")
        imgFooter.src = CDCbluePDFFooterIconBase64Url()
        imgFooter.style.cssText = "float:left;margin-right:10px"

        var today = new Date()
        const footerText = document.createElement("div")
        footerText.innerText = "Centers for Disease Control and Prevention, National Center for Chronic Disease Prevention and Health Promotion. Division of Reproductive Health[accessed " + today.toLocaleDateString() + "]."
        footerText.style.cssText = "font-family:arial;font-size:1.1rem;width:930px;margin-top:15px"

        const footer = document.createElement("div")
        footer.style.cssText = "overflow:hidden;margin-top:20px;margin-left:40px;"
        footer.appendChild(imgFooter)
        footer.appendChild(footerText)
        parentDiv.appendChild(footer)

        var visualTabIndex = tabIndex + 1

        try {
            //media print
            window.print();

        } catch (error) {
            console.log('saveToPDFHandler Error: ', error)
        }
        console.log('saveToPDFHandler: end')
    }
    function currentTabHandler() {
        switch (tabIndex) {
            case 0:
                return <Tab0
                    year={fetchYear}
                    isLoading={tab0_isLoading}
                    tables={tab0_tables}
                    isArtDashboard={isArtDashBoard}
                    reportingYear={reportingYear}
                    clinicStatus={clinicStatus}
                    footnotes={footnotes}
                    footnotesStatus={clinicStatus}
                    googleMapKey={googleMapKey} />;
            case 1:
                return <Tab1
                    isLoading={tab1_isLoading}
                    tables={tab1_tables}
                    tablesNational={tab1_tablesNational}
                    clinicStatus={clinicStatus}
                    footnotes={footnotes} />;
            case 2:
                return <Tab2
                    isLoading={tab2_isLoading}
                    tables={tab2_tables}
                    tablesNational={tab2_tablesNational}
                    clinicStatus={clinicStatus}
                    footnotes={footnotes} />;
            case 3:
                return <Tab3
                    isLoading={tab3_isLoading}
                    tables={tab3_tables}
                    tablesNational={tab3_tablesNational}
                    clinicStatus={clinicStatus}
                    currentTab={4}
                    footnotes={footnotes} />;
            default:
                return <Tab4
                    isLoading={tab4_isLoading}
                    tables={tab4_tables}
                    clinicStatus={clinicStatus}
                    footnotes={footnotes} />;
        }
    }

    var stateAbbr = {
        "Alabama": "AL",
        "Alaska": "AK",
        "American Samoa": "AS",
        "Arizona": "AZ",
        "Arkansas": "AR",
        "California": "CA",
        "Colorado": "CO",
        "Connecticut": "CT",
        "Delaware": "DE",
        "District of Columbia": "DC",
        "Federated States Of Micronesia": "FM",
        "Florida": "FL",
        "Georgia": "GA",
        "Guam": "GU",
        "Hawaii": "HI",
        "Idaho": "ID",
        "Illinois": "IL",
        "Indiana": "IN",
        "Iowa": "IA",
        "Kansas": "KS",
        "Kentucky": "KY",
        "Louisiana": "LA",
        "Maine": "ME",
        "Marshall Islands": "MH",
        "Maryland": "MD",
        "Massachusetts": "MA",
        "Michigan": "MI",
        "Minnesota": "MN",
        "Mississippi": "MS",
        "Missouri": "MO",
        "Montana": "MT",
        "Nebraska": "NE",
        "Nevada": "NV",
        "New Hampshire": "NH",
        "New Jersey": "NJ",
        "New Mexico": "NM",
        "New York": "NY",
        "North Carolina": "NC",
        "North Dakota": "ND",
        "Northern Mariana Islands": "MP",
        "Ohio": "OH",
        "Oklahoma": "OK",
        "Oregon": "OR",
        "Palau": "PW",
        "Pennsylvania": "PA",
        "Puerto Rico": "PR",
        "Rhode Island": "RI",
        "South Carolina": "SC",
        "South Dakota": "SD",
        "Tennessee": "TN",
        "Texas": "TX",
        "Utah": "UT",
        "Vermont": "VT",
        "Virgin Islands": "VI",
        "Virginia": "VA",
        "Washington": "WA",
        "West Virginia": "WV",
        "Wisconsin": "WI",
        "Wyoming": "WY",
        "None": "NA"
    },
        stateDescription = "";

    let stateImage = "";
    try {
        if (!tab0_isLoading) {
            if (isNationalData) {
                stateImage = "images/state/Shared.Images.Blue.US.png";
                stateDescription = "United States";
            } else {
                stateImage = "images/state/Shared.Images.Blue." +
                    stateAbbr[tab0_tables.ClinicInfo.StateDesc] + ".png";
                stateDescription = tab0_tables.ClinicInfo.StateDesc;
            }
        }
    } catch (er) {
        console.log(er);
    }




    return (
        <>
            <main>
                <div className="container pl-0 py-2">
                    {hasData && !isArtDashBoard && <a id="artReportingHomePageLink" className="d-print-none" href={homePageLink}>Return to Submission Process</a>}
                </div>

                <div className="container d-flex flex-wrap body-wrapper bg-white">
                    <section className="row col-12">
                        <div className="col-12 px-0">
                            <h1 className="pageHeading d-print-none text-primary">{pageHeading}</h1>
                            {hasData &&
                                (
                                    <div className="columns mt-2">
                                        <div style={{ float: "right" }}>
                                            <div className="d-print-none">
                                                {!IsArtDashboard() &&
                                                    <div className="links_block border p-2 rounded">
                                                        <span
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={saveToPDFHandler}
                                                            onKeyPress={(e) => e.key === 'Enter' && saveToPDFHandler()}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <span className="fill-p cdc-icon-pdf mr-1" style={{ fontSize: "20px" }}></span>
                                                            Save as PDF (.pdf)
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <h1 className="tabName d-none ml-0 my-0">{pageTitle}</h1>
                                        <div id="pdfDivFacilityName" className="column is-size-4 is-selfvcentered">
                                            <h2 className="my-0 font-weight-normal">
                                                <img
                                                    style={isNationalData ? { width: '60px' } : undefined}
                                                    className={`${isNationalData ? "mr-3" : "pr-3"} d-print-none`}
                                                    src={isNationalData ? "/images/state/Shared.Images.Blue.US.png" : stateImage}
                                                    alt=""
                                                    aria-hidden="true"
                                                />
                                                {isNationalData ? "National Data" : (!tab0_isLoading && tab0_tables.ClinicInfo?.FacilityName)}
                                                {!tab0_isLoading && tab0_tables.ClinicInfo && (() => {
                                                    if (!sessionStorage.getItem('ClinicName')) {
                                                        sessionStorage.setItem('ClinicName', tab0_tables.ClinicInfo.FacilityName);
                                                    }
                                                    sessionStorage.setItem('selectedState', tab0_tables.ClinicInfo.StateDesc);
                                                    return null;
                                                })()}
                                            </h2>
                                            <h3 className="d-none d-print-block my-0 font-weight-normal">{tabTitles[tabIndex]}</h3>
                                        </div>

                                        {isArtDashBoard && <NavigationHeader onDataTypeChange={handleDataTypeChange} />}
                                    </div>
                                )
                            }
                            {
                                !tab0_isLoading && !hasData ? <NoData homeLink={homePageLink} /> : <div className="col-12 tabs is-boxed is-boxed p-1 rounded tabs_block">
                                    <ul className="nav nav-tabs pt-2 d-print-none" id="tabs-nav" role="tablist">
                                        <li
                                            className={`${tabIndex === 0 ? "active" : ""} nav-item mb-0 rounded-top mr-2`}
                                            role="presentation"
                                        >
                                            {tab0_isLoading ? (
                                                <span
                                                    className='active nav-link bg-gray-l3 pt-3 tabBtn'
                                                    role="tab"
                                                    aria-selected="false"
                                                    aria-label="Loading Clinic Services & Profile tab"
                                                >
                                                    <Oval
                                                        height={20}
                                                        width={20}
                                                        color="#005ea2"
                                                        wrapperStyle={{ "display": "block", "position": "absolute", "top": "13px" }}
                                                        wrapperClass=""
                                                        visible={true}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#dee2e6"
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}
                                                    />
                                                    Loading Data...
                                                </span>
                                            ) : (
                                                <span
                                                    id="linkTab0"
                                                    className={`${tabIndex === 0 ? "active" : ""} nav-link bg-gray-l3 tabBtn`}
                                                    onClick={() => changeTabHandler(0)}
                                                    role="tab"
                                                    tabIndex={0}
                                                    onKeyPress={(e) => e.key === 'Enter' && changeTabHandler(0)}
                                                    aria-selected={tabIndex === 0}
                                                    aria-label={`Clinic Services & Profile tab${tabIndex === 0 ? ', selected' : ''}`}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    Clinic Services & Profile
                                                </span>
                                            )}
                                        </li>
                                        <li
                                            className={`${tabIndex === 1 ? "active font-weight-bold" : ""} nav-item mb-0 rounded-top mr-2`}
                                            role="presentation"
                                        >
                                            {tab1_isLoading ? (
                                                <span
                                                    className='nav-link bg-gray-l3 pt-3 tabBtn'
                                                    role="tab"
                                                    aria-selected="false"
                                                    aria-label="Loading Patient & Cycle Characteristics tab"
                                                >
                                                    <Oval
                                                        height={20}
                                                        width={20}
                                                        color="#005ea2"
                                                        wrapperStyle={{ "display": "block", "position": "absolute", "top": "13px" }}
                                                        wrapperClass=""
                                                        visible={true}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#dee2e6"
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}
                                                    />
                                                    Loading Data...
                                                </span>
                                            ) : (
                                                <span
                                                    id="linkTab1"
                                                    className={`${tabIndex === 1 ? "active" : ""} nav-link bg-gray-l3 tabBtn`}
                                                    onClick={() => changeTabHandler(1)}
                                                    role="tab"
                                                    tabIndex={0}
                                                    onKeyPress={(e) => e.key === 'Enter' && changeTabHandler(1)}
                                                    aria-selected={tabIndex === 1}
                                                    aria-label={`Patient & Cycle Characteristics tab${tabIndex === 1 ? ', selected' : ''}`}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    Patient & Cycle Characteristics
                                                </span>
                                            )}
                                        </li>
                                        <li
                                            className={`${tabIndex === 2 ? "active font-weight-bold" : ""} nav-item mb-0 rounded-top mr-2`}
                                            role="presentation"
                                        >
                                            {tab2_isLoading ? (
                                                <span
                                                    className='nav-link bg-gray-l3 pt-3 tabBtn'
                                                    role="tab"
                                                    aria-selected="false"
                                                    aria-label="Loading Success Rates: Patients Using Own Eggs tab"
                                                >
                                                    <Oval
                                                        height={20}
                                                        width={20}
                                                        color="#005ea2"
                                                        wrapperStyle={{ "display": "block", "position": "absolute", "top": "13px" }}
                                                        wrapperClass=""
                                                        visible={true}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#dee2e6"
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}
                                                    />
                                                    Loading Data...
                                                </span>
                                            ) : (
                                                <span
                                                    id="linkTab2"
                                                    className={`${tabIndex === 2 ? "active" : ""} nav-link bg-gray-l3 tabBtn`}
                                                    onClick={() => changeTabHandler(2)}
                                                    role="tab"
                                                    tabIndex={0}
                                                    onKeyPress={(e) => e.key === 'Enter' && changeTabHandler(2)}
                                                    aria-selected={tabIndex === 2}
                                                    aria-label={`Success Rates: Patients Using Own Eggs tab${tabIndex === 2 ? ', selected' : ''}`}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    Success Rates: Patients Using Own Eggs
                                                </span>
                                            )}
                                        </li>
                                        <li
                                            className={`${tabIndex === 3 ? "active font-weight-bold" : ""} nav-item mb-0 rounded-top mr-2`}
                                            role="presentation"
                                        >
                                            {tab3_isLoading ? (
                                                <span
                                                    className='nav-link bg-gray-l3 pt-3 tabBtn'
                                                    role="tab"
                                                    aria-selected="false"
                                                    aria-label="Loading Success Rates: Patients Using Donor Eggs tab"
                                                >
                                                    <Oval
                                                        height={20}
                                                        width={20}
                                                        color="#005ea2"
                                                        wrapperStyle={{ "display": "block", "position": "absolute", "top": "13px" }}
                                                        wrapperClass=""
                                                        visible={true}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#dee2e6"
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}
                                                    />
                                                    Loading Data...
                                                </span>
                                            ) : (
                                                <span
                                                    id="linkTab3"
                                                    className={`${tabIndex === 3 ? "active" : ""} nav-link bg-gray-l3 tabBtn`}
                                                    onClick={() => changeTabHandler(3)}
                                                    role="tab"
                                                    tabIndex={0}
                                                    onKeyPress={(e) => e.key === 'Enter' && changeTabHandler(3)}
                                                    aria-selected={tabIndex === 3}
                                                    aria-label={`Success Rates: Patients Using Donor Eggs tab${tabIndex === 3 ? ', selected' : ''}`}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    Success Rates: Patients Using Donor Eggs
                                                </span>
                                            )}
                                        </li>
                                        <li
                                            className={`${tabIndex === 4 ? "active font-weight-bold" : ""} nav-item mb-0 rounded-top mr-2`}
                                            role="presentation"
                                        >
                                            {tab4_isLoading ? (
                                                <span
                                                    className='nav-link bg-gray-l3 pt-3 tabBtn'
                                                    role="tab"
                                                    aria-selected="false"
                                                    aria-label="Loading Clinic Data Summary tab"
                                                >
                                                    <Oval
                                                        height={20}
                                                        width={20}
                                                        color="#005ea2"
                                                        wrapperStyle={{ "display": "block", "position": "absolute", "top": "13px" }}
                                                        wrapperClass=""
                                                        visible={true}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#dee2e6"
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}
                                                    />
                                                    Loading Data...
                                                </span>
                                            ) : (
                                                <span
                                                    id="linkTab4"
                                                    className={`${tabIndex === 4 ? "active" : ""} nav-link bg-gray-l3 tabBtn`}
                                                    onClick={() => changeTabHandler(4)}
                                                    role="tab"
                                                    tabIndex={0}
                                                    onKeyPress={(e) => e.key === 'Enter' && changeTabHandler(4)}
                                                    aria-selected={tabIndex === 4}
                                                    aria-label={`Clinic Data Summary tab${tabIndex === 4 ? ', selected' : ''}`}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    Clinic Data Summary
                                                </span>
                                            )}
                                        </li>
                                    </ul>
                                    <div className={`tab-content small-font tabContent${tabIndex}`} id="tabContent">{currentTabHandler()}</div>

                                    <div className="container printFooter d-none">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <img
                                                    src="/images/CDCbluePDFFooterIcon.jpg"
                                                    alt="cdc footer icon"
                                                    className="img-fluid"
                                                />
                                            </div>
                                            <div className="col">
                                                <p className="text-wrap">
                                                    Centers for Disease Control and Prevention, National Center for
                                                    Chronic Disease Prevention and Health Promotion. Division of
                                                    Reproductive Health [accessed {today.toLocaleDateString()}].
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </section>
                </div >

            </main >
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
}; // .mapStateToProps

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabPage)
