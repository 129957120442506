import { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import BarChartVertical from "../charts/BarChartVertical";
import BarChartVerticalSmall from "../charts/BarChartVerticalSmall";
import BarChartHorizontal from "../charts/BarChartHorizontal";
import TabularChart from "../charts/TabularChart";
import TabularChartReason from "../charts/TabularChartReason";
import { Spinner } from "../spinner/Spinner";
import { Modal, Button } from 'react-bootstrap';
import { generate_New_Filter_ArrayObj } from "../../util";
import FocusLock from 'react-focus-lock';
import { IsArtDashboard } from "../../api/index";

function Tab1({ user, actions, isLoading, tables, tablesNational, clinicStatus, footnotes }) {
    //console.log(tables)
    const tabTitle = "Patient & Cycle Characteristics";
    const filterOption2_1 = generate_New_Filter_ArrayObj(tables.Questions, 0, 210);
    const isNationalData = sessionStorage.getItem('ClinicId') === '9999' ? true : false;
    const isArtDashBoard = IsArtDashboard();

    const [filterVal2, setFilterVal2] = useState(0);
    const [showTabularChart, setShowTabularChart] = useState(false);
    //setting show description text container
    const [truncateText1, setTruncateText1] = useState(true);
    const [truncateText2, setTruncateText2] = useState(true);
    //national data toggle
    const [showNational, setShowNational] = useState(false);


    //setting modal
    const [visibleFilterMeta, setVisibleFilterMeta] = useState(false);
    const btnCloseFilterMeta = () => {
        setVisibleFilterMeta(false);
        document.body.removeAttribute('aria-hidden');
    };
    const btnShowFilterMeta = () => {
        setVisibleFilterMeta(true);
        document.body.setAttribute('aria-hidden', 'true');
    };

    //useSetTitle(tabTitle, true);

    useEffect(() => {
        return () => {
            // Remove aria-hidden from body on component unmount
            document.body.removeAttribute('aria-hidden');
        };
    }, []);

    if (isLoading) {
        return (
            <div>
                <>
                    <Spinner
                        message="Loading Data..."
                        backGround={true}
                        center={true}
                    />
                </>
            </div>
        )
    }

    const changeFilter = (index2) => {
        setFilterVal2(index2);
    };


    const getSelectBoxText = () => {
        var useFilterOption = filterOption2_1;
        return useFilterOption[filterVal2].text;
    }

    let resultBotDivCSS = { display: "flex" };

    const questions = tables.Questions
    if (typeof (questions) == 'undefined' || questions == null) return <p>Error: cannot find Questions.</p>;
    // console.log(questions)
    for (let i = 0; i < questions.length; i++) {
        let question = questions[i]
        let questOption1 = filterOption2_1.find(questOption => questOption.value === question.QuestionsId)
        if (questOption1) questOption1.text = questions[i].Question
        else {
            console.log('Warning: unexpected question found ' + question.QuestionsId)
        }
        // console.log(question)
    }
    // console.log(filterOption2_1)
    // console.log(filterOption2_2)

    var useFilterOption = filterOption2_1;
    //console.log(useFilterOption);
    let option2List = useFilterOption.map((filter, index) => {
        // to see questioIO, use: {filter.value} {filter.text}       
        return (
            <option key={filter.value} value={index}>
                {filter.text}
            </option>
        )
    });

    //console.log('Debug - Filter Options and Selected Index:', useFilterOption, filterVal2);

    let questionId, questionData, questionDataNational, showChart;

    try {
        // Get question ID
        questionId = useFilterOption?.[filterVal2]?.value ? `${useFilterOption[filterVal2].value}` : null;

        // Only proceed with data lookup if we have a valid questionId
        if (questionId) {
            questionData = tables?.Data?.find(question => question.QuestionId === questionId);
            questionDataNational = tablesNational?.Data?.find(question => question.QuestionId === questionId);

            // Safely check if chart should be shown
            showChart = questionId === "Q002" ? true :
                questionData?.DataRows?.[0]?.[5] === 'True';
        }
    } catch (error) {
        console.error('Error processing question data:', error);
        // Log error but allow component to continue rendering
    }

    if ((typeof (questionData) == 'undefined' || questionData == null) && footnotes.length <= 0) return <p>Error: cannot find data for question.</p>;

    const questionKey = questionId
    // const tableHeaderText = questionData.TableHeader
    var tableFooterText;
    let tableFooterFractional,
        tableFooterSupressed;

    try {
        // const tableHeaderText = questionData.TableHeader
        tableFooterText = questionData.CycleCountMessage;
        tableFooterFractional = questionData.FractionalDataMessage;
        tableFooterSupressed = questionData.SupressedDataMessage;

        for (var i = tableFooterText.length - 1, c = 0; i >= 0; i--) {
            if (tableFooterText.charAt(i) >= '0' && tableFooterText.charAt(i) <= '9') {
                c++
            } else {
                c = 0
            }
            if (c === 4) {
                tableFooterText = tableFooterText.substring(0, i + 1) + ',' + tableFooterText.substring(i + 1)
                break;
            }
        }

    } catch (e) {

    }

    var tableFooterTextnational;

    try {
        tableFooterTextnational = questionDataNational.CycleCountMessage

        for (let i = tableFooterTextnational.length - 1, c = 0; i >= 0; i--) {
            if (tableFooterTextnational.charAt(i) >= '0' && tableFooterTextnational.charAt(i) <= '9') {
                c++
            } else {
                c = 0
            }
            if (c === 4) {
                tableFooterTextnational = tableFooterTextnational.substring(0, i + 1) + ',' + tableFooterTextnational.substring(i + 1)
                break;
            }
        }

    } catch (e) {
        //silent fail
    }
    let Report;
    let title = 'Age of Patient'

    // In the switch statement where Report is defined, modify each chart component to include the selectedText prop:

    switch (questionId) {
        case "Q002":
            title = 'Reason'
            if (!showTabularChart && showChart) {
                Report =
                    <BarChartHorizontal
                        data={questionData}
                        dataNational={questionDataNational}
                        key={questionKey}
                        showNational={showNational}
                        selectedText={getSelectBoxText()} // Add this line
                    />
            } else {
                Report =
                    <TabularChartReason
                        data={questionData}
                        dataNational={questionDataNational}
                        key={questionKey}
                        showFractional={false}
                        showNational={showNational}
                        selectedText={getSelectBoxText()} // Add this line
                    />
            }
            break;
        case "Q001":
        case "Q004":
        case "Q003":
        case "Q005":
        case "Q006":
        case "Q101":
        case "Q102":
        case "Q007":
        case "Q008":
        case "Q009":
        case "Q010":
        case "Q110":
            if (!showTabularChart && showChart) {
                Report =
                    <BarChartVertical
                        data={questionData}
                        dataNational={questionDataNational}
                        showNational={showNational}
                        key={questionKey}
                        title={title}
                        chartTitle={tabTitle}
                        selectedText={getSelectBoxText()}
                    />
            } else {
                Report =
                    <TabularChart
                        data={questionData}
                        dataNational={questionDataNational}
                        showNational={showNational}
                        key={questionKey}
                        dataRowsFormat='tab1'
                        showFractional={false}
                        selectedText={getSelectBoxText()} // 
                    />
            }
            break;
        default:
            if (!showTabularChart) {
                Report =
                    <BarChartVerticalSmall
                        data={questionData}
                        dataNational={questionDataNational}
                        showNational={showNational}
                        key={questionKey}
                        chartTitle={tabTitle}
                        selectedText={getSelectBoxText()} // 
                    />
            } else {
                Report =
                    <TabularChart
                        data={questionData}
                        dataNational={questionDataNational}
                        showNational={showNational}
                        key={questionKey}
                        dataRowsFormat='tab1NoPercent'
                        showFractional={false}
                        selectedText={getSelectBoxText()}
                    />
            }
            break;
    }

    return (
        <>
            <div className="tab_in pt-2 px-5">
                <Modal
                    show={visibleFilterMeta}
                    onHide={btnCloseFilterMeta}
                    className="metaDataTableModal"
                    aria-labelledby="metaDataTableModal"
                    backdrop="static"
                    keyboard={true}
                    centered
                >
                    <FocusLock returnFocus disabled={!visibleFilterMeta}>
                        <Modal.Header>
                            <Modal.Title id="metaDataTableModal">Descriptions of Reasons</Modal.Title>
                            <Button
                                onClick={btnCloseFilterMeta}
                                className="bg-transparent border-0 text-dark"
                                aria-label="Close reasons description modal"
                            >
                                <span aria-hidden="true">X</span>
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ height: "600px", overflow: "auto" }} tabIndex="0">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="h5 py-3">Name</th>
                                            <th className="h5 py-3">Description</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table table-bordered bl-0" >
                                        {tables.Reasons && tables.Reasons.map((row, index) => (
                                            <tr key={index} >
                                                <td className="small">{row.Reason}</td>
                                                <td className="small">{row.ReasonDescription}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={btnCloseFilterMeta}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </FocusLock>
                </Modal>
                {clinicStatus === "None" && footnotes && footnotes.length > 0 && (
                    <div className="alert-info mb-3 p-3 d-flex">
                        <span className="fill-p cdc-icon-info-circle-solid mr-2" aria-hidden="true"></span>
                        <div>
                            {footnotes[0].FootNote}
                        </div>
                    </div>
                )}
                {clinicStatus !== "None" && <div>
                    <div id="tab2Title1" className={truncateText1 ? "truncate-overflow" : ""} >
                        {tables.TopicDescription[0].TopicDescription}
                    </div>
                    <div className="d-print-none text-right mt-1">
                        <a
                            href="#/"
                            onClick={() => setTruncateText1(!truncateText1)}
                        >
                            {truncateText1 ? "Show full description" : "Hide full description"}
                            <i className={`align-middle ml-1 fa fa-2x ${truncateText1 ? "fa-angle-down" : "fa-angle-up"}`}></i>
                        </a>
                    </div>

                    <div className="bordered_grey_bot">
                        <div className="top">
                            <div className="columns">
                                <p className="mb-1">
                                    <b id="lblTab2Question" className="d-print-none">Select a patient or cycle characteristic</b>
                                </p>
                                <div className="row">
                                    <div className="column col-12">
                                        <select
                                            id="tab2Question"
                                            className="control-input"
                                            value={filterVal2}
                                            key={filterVal2}
                                            onChange={(e) => {
                                                changeFilter(e.target.value);
                                            }}
                                            aria-labelledby="lblTab2Question"
                                        >
                                            {option2List}
                                        </select>
                                    </div>
                                    <div className="column col-12 font-italic is-end is-selfvcentered mt-3 d-print-none">
                                        viewing {useFilterOption[filterVal2].index} of {useFilterOption.length}
                                        <button
                                            className="btn btn-secondary is-small is-info ml-4 mr-2 border-0"
                                            disabled={
                                                useFilterOption[filterVal2].index ===
                                                1
                                            }
                                            onClick={() => {
                                                changeFilter(+filterVal2 - 1);
                                            }}
                                        >
                                            Previous
                                        </button>
                                        <button
                                            className="btn btn-secondary is-small is-info border-0"
                                            disabled={
                                                useFilterOption[filterVal2].index ===
                                                useFilterOption.length
                                            }
                                            onClick={() => {
                                                changeFilter(+filterVal2 + 1);
                                            }}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <p className={`mt-3 mb-2  ${truncateText2 ? "truncate-overflow" : ""} `} id="tab2Title2">
                                {useFilterOption[filterVal2].header}
                            </p>
                            {useFilterOption[filterVal2].header.length > 250 &&
                                <div className="d-print-none text-right mt-1">
                                    <a
                                        href="#/"
                                        onClick={() => setTruncateText2(!truncateText2)}
                                    >
                                        {truncateText2 ? "Show full description" : "Hide full description"}
                                        <i className={`align-middle ml-1 fa fa-2x ${truncateText2 ? "fa-angle-down" : "fa-angle-up"}`}></i>
                                    </a>
                                </div>
                            }
                        </div>


                        {!isNationalData && isArtDashBoard &&
                            <div className="col-12 my-2 float-left d-print-none">
                                <label className="mr-3 mb-0 ml-1">
                                    <input
                                        type="checkbox"
                                        checked={showNational}
                                        onChange={() => setShowNational(!showNational)}
                                        className="mr-2"
                                    />
                                    Show National Data
                                </label>
                            </div>
                        }


                        <div className="bot">
                            <div id="pdfDiv" className={`tab1PdfDiv pt-4 ${questionKey}`}>
                                <div className="columns">
                                    <div className="column">
                                        <div className="filter_result">
                                            <div
                                                className="filter_result_bot"
                                            // style={resultBotDivCSS}
                                            >
                                                <div style={resultBotDivCSS}>
                                                    <div style={{ float: "left" }}>
                                                        <p className="float-left mr-1">{getSelectBoxText()}</p>
                                                        {useFilterOption[filterVal2].index === 2 && <button
                                                            className="bg-dark button js-modal-trigger question_btn text-white d-print-none"
                                                            data-target="modal_help_1"
                                                            aria-haspopup="dialog"
                                                            aria-label="Learn more about reasons"
                                                            onClick={btnShowFilterMeta}
                                                        >
                                                            <span aria-hidden="true">?</span>
                                                        </button>
                                                        }
                                                    </div>
                                                </div>

                                                <div style={{ float: "right" }} className="d-print-none">

                                                    {showChart &&
                                                        (<><button
                                                            style={{ padding: "0px 0px", borderWidth: "0px" }}
                                                            onClick={() => { setShowTabularChart(false); }}
                                                            aria-label={showTabularChart ? "Switch to chart view" : "Currently in chart view"}
                                                            aria-pressed={!showTabularChart}
                                                        >
                                                            <img
                                                                src={!showTabularChart ? "images/chart-toggle-selected.png" : "images/chart-toggle-unselected.png"}
                                                                alt=""
                                                                aria-hidden="true"
                                                            />
                                                        </button>

                                                            <button
                                                                style={{ padding: "0px 0px", borderWidth: "0px" }}
                                                                onClick={() => { setShowTabularChart(true); }}
                                                                aria-label={showTabularChart ? "Currently in table view" : "Switch to table view"}
                                                                aria-pressed={showTabularChart}
                                                            >
                                                                <img
                                                                    src={showTabularChart ? "images/tabular-toggle-selected.png" : "images/tabular-toggle-unselected.png"}
                                                                    alt=""
                                                                    aria-hidden="true"
                                                                />
                                                            </button></>)
                                                    }
                                                </div>
                                                <div style={resultBotDivCSS}>
                                                    <div style={{ float: "center" }}>
                                                        <p>
                                                            <b> <br /></b>
                                                        </p>
                                                    </div>
                                                </div>
                                                {showTabularChart &&
                                                    <div style={{ textAlign: "center" }}>
                                                        <p className="pb-0 mb-0">
                                                            <b>{title}<br /></b>
                                                        </p>
                                                    </div>
                                                }

                                                <div
                                                    className="result_table tab1_result_table pt-1"
                                                    style={{
                                                        margin: "0 auto",
                                                        position: "relative",
                                                        overflow: "hidden",
                                                        maxWidth: "100%",
                                                        textAlign: "left",
                                                        lineHeight: "normal",
                                                        zindex: 0,
                                                        WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                                    }}
                                                >
                                                    {Report}

                                                    <div
                                                        className="highcharts-tooltip"
                                                        style={{
                                                            position: "absolute",
                                                            left: "242px",
                                                            top: "-9999px",
                                                            visibility: "visible",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                position: "absolute",
                                                                whiteSpace: "nowrap",
                                                                fontFamily: "Arial, Helvetica, sans-serif",
                                                                fontSize: "12px",
                                                                color: "rgb(51, 51, 51)",
                                                                marginLeft: "0px",
                                                                marginTop: "0px",
                                                                left: "0px",
                                                                top: "0px",
                                                            }}
                                                            zindex="1"
                                                        >
                                                            <div className="rdquicktip-content">
                                                                <div className="body">
                                                                    <table className="rdquicktip-table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td></td>
                                                                                <td id="tooltipText">
                                                                                    35-37 <br /> Age of patient <br /> 15.6
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>

                                                </div>
                                                <div className={`tab1FooterCycleCount ${showTabularChart}`}>
                                                    <div dangerouslySetInnerHTML={{ __html: showTabularChart ? tableFooterText.replace("d-none", "") : !showNational && isNationalData ? ('National ' + tableFooterText).replace(/Clinic/g, '') : tableFooterText }} />
                                                    {!isNationalData && isArtDashBoard && showNational && <div dangerouslySetInnerHTML={{ __html: !showNational && isNationalData ? tableFooterTextnational.replace("d-none", "") : ('National ' + tableFooterTextnational).replace(/Clinic/g, '') }} />}
                                                    {tableFooterFractional}<br />
                                                    <div dangerouslySetInnerHTML={{ __html: tableFooterSupressed }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                }
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
}; // .mapStateToProps

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tab1)