/**
 * Filters clinic data based on text search and selected services/profiles
 * Uses the clinic.service_profile_id field which contains comma-separated service/profile IDs
 * 
 * @param {Array} clinics - Array of clinic objects
 * @param {string} searchText - Text to search in facility name or city
 * @param {Array} selectedServices - Array of selected service IDs
 * @param {Array} selectedProfiles - Array of selected profile IDs
 * @param {Object} serviceLabels - Mapping of service IDs to labels (not used in filtering but kept for interface consistency)
 * @param {Object} profileLabels - Mapping of profile IDs to labels (not used in filtering but kept for interface consistency)
 * @returns {Array} Filtered array of clinics
 */
export const filterClinicData = (clinics, searchText, selectedServices, selectedProfiles, serviceLabels, profileLabels) => {
    if (!clinics) return [];
    
    return clinics.filter(clinic => {
        // Text search filter - check facility name and city
        const searchMatch = !searchText || (
            (clinic.FacilityName?.toLowerCase().includes(searchText.toLowerCase()) ||
             clinic.City?.toLowerCase().includes(searchText.toLowerCase()))
        );

        // Get array of service and profile IDs for this clinic
        const serviceProfileIds = clinic.service_profile_id
            ? clinic.service_profile_id.split(',').map(id => id.trim())
            : [];

        // Check if clinic has ALL selected services
        const serviceMatch = selectedServices.length === 0 || 
            selectedServices.every(serviceId => 
                serviceProfileIds.includes(serviceId)
            );

        // Check if clinic has ALL selected profiles
        const profileMatch = selectedProfiles.length === 0 || 
            selectedProfiles.every(profileId => 
                serviceProfileIds.includes(profileId)
            );

        // Return true only if all conditions match
        return searchMatch && serviceMatch && profileMatch;
    });
};

/**
 * Helper function to check if a clinic has a specific service or profile
 * @param {Object} clinic - The clinic object
 * @param {string} id - The service or profile ID to check
 * @returns {boolean} Whether the clinic has the service/profile
 */
export const hasServiceOrProfile = (clinic, id) => {
    if (!clinic.service_profile_id) return false;
    const serviceProfileIds = clinic.service_profile_id.split(',').map(id => id.trim());
    return serviceProfileIds.includes(id);
};

/**
 * Helper function to get all services and profiles for a clinic
 * @param {Object} clinic - The clinic object
 * @returns {Array} Array of service and profile IDs
 */
export const getClinicServicesAndProfiles = (clinic) => {
    if (!clinic.service_profile_id) return [];
    return clinic.service_profile_id.split(',').map(id => id.trim());
};