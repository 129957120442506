import React, { Component } from "react";
import * as d3 from "d3";
import { returnDecimalNum } from "../../../util";

class BarChart extends Component {
    constructor(props) {
        super(props);
        const clinicId = sessionStorage.getItem('ClinicId');
        this.state = {
            data: this.props.data,
            dataNational: this.props.dataNational,
            title: this.props.title,
            yAxisFormat: this.props.yAxisFormat,
            showNational: clinicId === '9999' ? false : (this.props.showNational || false),
            questionText: this.props.selectedText,
            currentTab: this.props.currentTab,
            chartTitle: this.props.chartTitle || 'Bar Chart'
        };
        this.ref = React.createRef();
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showNational !== this.props.showNational) {
            const clinicId = sessionStorage.getItem('ClinicId');
            this.setState({
                showNational: clinicId === '9999' ? false : this.props.showNational
            }, () => {
                d3.select(this.ref.current).selectAll("*").remove();
                this.init();
            });
        }
    }

    init() {
        const report = this.state.data;
        const questionText = this.state.questionText;
        const reportNational = this.state.dataNational;
        const showNational = this.state.showNational;
        const title = this.state.title;
        const yAxisFormat = this.state.yAxisFormat;
        let yAxisLabel = "Percent (%)";
        let yAxisLabels = [0, 25, 50, 75, 100];
        let yAxisTop = 100;
        let yAxisSpacing = 73.2;

        const handleTooltip = (event, d, isNational) => {
            const currentBar = d3.select(event.currentTarget);
            const i = parseInt(currentBar.attr("data-index"));
            const barX = parseInt(currentBar.attr("x"));

            if (showNational) {
                // Get both bars for the current index
                const clinicBar = series
                    .select('.highcharts-series:first-of-type')
                    .select(`rect[data-index="${i}"]`);

                const nationalBar = series
                    .select('.highcharts-series:nth-of-type(2)')
                    .select(`rect[data-index="${i}"]`);

                // Get Y positions
                const clinicY = clinicBar.empty() ? 291 : parseInt(clinicBar.attr("y"));
                const nationalY = nationalBar.empty() ? 291 : parseInt(nationalBar.attr("y"));

                // Use the higher position (smaller Y value)
                const tooltipY = Math.min(clinicY, nationalY) - 30;

                currentBar.style("opacity", "0.8");

                d3.select("div.highcharts-tooltip")
                    .style("left", `${barX + 120}px`)
                    .style("top", `${tooltipY + 20}px`);

            } else {
                // If no national data, just use current bar position
                const barY = parseInt(currentBar.attr("y"));

                currentBar.style("opacity", "0.8");

                d3.select("div.highcharts-tooltip")
                    .style("left", `${barX + 120}px`)
                    .style("top", `${barY - 10}px`);
            }

            // Set tooltip text maintaining existing format
            const headers = isNational ? reportNational.RowHeaders : report.RowHeaders;
            d3.select("#tooltipText").html(
                `${isNational ? "National " : ""}Age of Patient ${headers[i + 1]}: ${Number(d).toFixed(1)}`
            );
        };

        //code refactor: method to return dynamic values for left hand legend that has format==number15. 
        function returnDynamicYaxisValue(arr) {
            let _arr = [...arr];

            //beginning and ending are not numbers, so we are removing them
            _arr.pop();
            _arr.shift();

            //make array a number array
            _arr = _arr.map(Number);

            //setting new modified array, how much to increment in the array and what is max value of array
            let maxVal = Math.max(..._arr),
                newArr = [],
                incrementNumber = Math.ceil(Number(maxVal / 5)), //dividing by 6 to account for 0 at first index
                currentVal = incrementNumber; //how much to increment each array value for y axis value
            if (maxVal !== 0) {
                //increment each array item evenly
                for (var i = 0; i <= 5; i++) {
                    newArr.push(currentVal)
                    currentVal = currentVal + incrementNumber;
                }

                //adding 0 for beginning index, and converting array to
                newArr.unshift(0)

                //getting max value in new array, need this for barchart height computation
                maxVal = Math.max(...newArr)
            } else {
                newArr = [0];
            }

            //return new array and max value in array
            return {
                maxArrayVal: maxVal,
                arrModifiedVal: newArr
            }
        }


        let dynamicYaxis = returnDynamicYaxisValue(this.state.data.DataRows);

        if (yAxisFormat === "number3") {
            yAxisLabel = "Number";
            yAxisLabels = [0, 0.5, 1, 1.5, 2, 2.5, 3];
            yAxisTop = 3;
            yAxisSpacing = 48.5;
        } else if (yAxisFormat === "number15") {
            yAxisLabel = "Number";
            yAxisLabels = dynamicYaxis.arrModifiedVal;
            yAxisTop = dynamicYaxis.maxArrayVal;
            yAxisSpacing = 48.5;
        }

        let row1header1 = report.RowHeaders[1];
        let row1header2 = report.RowHeaders[2];
        let row1header3 = report.RowHeaders[3];
        let row1header4 = report.RowHeaders[4];
        let row2header1 = "";
        let row2header2 = "";
        let row2header3 = "";
        let row2header4 = "";

        if (row1header1 === "Fresh embryos fresh eggs") {
            row1header1 = "Fresh Embryos";
            row2header1 = "Fresh Eggs";
            row1header2 = "Fresh Embryos";
            row2header2 = "Frozen Eggs";
            row1header3 = "Frozen Embryos";
            row1header4 = "Donated";
            row2header4 = "Embryos";
        }

        var svg = this.ref.current;

        svg = d3
            .select(svg)
            .attr("version", "1.1")
            .attr(
                "style",
                "font-size: 12px;"
            );

        svg
            .append("defs")
            .append("clipPath")
            .append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", 587)
            .attr("height", 290);

        svg
            .append("rect")
            .attr("x", 0.5)
            .attr("y", 0.5)
            .attr("width", 666)
            .attr("height", 398)
            .attr("strokeWidth", 1)
            .attr("rx", 4)
            .attr("ry", 4)
            .attr("fill", "#FFFFFF")
            .attr("stroke", "rgba(255, 255, 255, 0)")
            .attr("class", "highcharts-background");

        svg
            .append("rect")
            .attr("x", 71)
            .attr("y", 10)
            .attr("width", 587)
            .attr("height", 290)
            .attr("fill", "#FFFFFF")
            .attr("stroke", "rgba(255, 255, 255, 0)")
            .attr("class", "highcharts-background");

        svg
            .append("desc")
            .text(`Bar chart showing ${questionText} by Age of patient`);
        svg
            .append("title")
            .text(title);

        svg
            .append("g")
            .attr("class", "highcharts-grid")
            .attr("zindex", 1)

            .selectAll("path")
            .data([366.5, 511.5, 658.5, 220.5, 74.5])
            .enter()

            .append("path")
            .attr("fill", "none")
            .attr("d", function (d) {
                return `M ${d} 10 L ${d} 301`;
            })
            .attr("stroke", "#FFFFFF")
            .attr("strokeWidth", 3)
            .attr("zindex", 1)
            .attr("opacity", 1);

        svg
            .append("g")
            .attr("class", "highcharts-grid") // horizontal lines
            .attr("zindex", 1)

            .selectAll("path")
            .data([9.5, 58.5, 107.5, 155.5, 203.5, 252.5, 300.5])
            .enter()

            .append("path")
            .attr("fill", "none")
            .attr("d", function (d) {
                return `M 75 ${d} L 658 ${d}`;
            })
            .attr("stroke", "#C0C0C0")
            .attr("strokeWidth", 1)
            .attr("zindex", 1)
            .attr("opacity", 1);

        var axisX = svg
            .append("g")
            .attr("class", "highcharts-axis")
            .attr("zindex", 2);

        axisX
            .append("text")
            .attr("x", 366.5)
            .attr("y", 343 + 5)
            .attr("zindex", 7)
            .attr("text-anchor", "middle")
            .attr("transform", `translate(0,  ${this.state.currentTab === 4 ? "15" : "0"})`)
            .attr("class", "highcharts-xaxis-title")
            .attr(
                "style",
                "color: #333333; font-weight: bold; font-size: 14px; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("visibility", "visible")

            .append("tspan")
            .text(title);

        axisX
            .append("path")
            .attr("fill", "none")
            .attr("d", "M 75 301.5 L 658 301.5")
            .attr("strokeWidth", 1)
            .attr("zindex", 7)
            .attr("visibility", "visible");

        var axisY = svg
            .append("g")
            .attr("class", "highcharts-axis")
            .attr("zindex", 2);

        axisY
            .append("text")
            .attr("x", 26.427734375)
            .attr("y", 155.5)
            .attr("zindex", 7)
            .attr("text-anchor", "middle")
            .attr("transform", "translate(0, 0) rotate(270 26.427734375 155.5)")
            .attr("class", "highcharts-yaxis-title")
            .attr(
                "style",
                "color: #333333; font-weight: bold; font-size: 14px; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("visibility", "visible")

            .append("tspan")
            .text(yAxisLabel);

        axisY
            .append("path")
            .attr("fill", "none")
            .attr("d", "M 74.5 10 L 74.5 301")
            .attr("strokeWidth", 1)
            .attr("zindex", 7)
            .attr("visibility", "visible");

        // clinic data start
        var series = svg
            .append("g")
            .attr("class", "highcharts-series-group")
            .attr("zindex", 3);

        // Calculate bar width and spacing based on whether national data is shown
        const barWidth = showNational ? 30 : 70;
        const barSpacing = showNational ? 10 : 0;

        // Clinic data bars
        series
            .append("g")
            .attr("class", "highcharts-series highcharts-tracker")
            .attr("visibility", "visible")
            .attr("zindex", 0.1)
            .attr("transform", "translate(51, 7.5) scale(1 1)")
            .selectAll("rect")
            .data([
                returnDecimalNum(report.DataRows[1].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[2].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[3].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[4].replace("%", "") * 1.0)
            ])
            .enter()
            .append("rect")
            .attr("data-index", (d, i) => i)
            .attr("x", function (d, index) {
                return 38 + 145 * index;
            })
            .attr("y", function (d) {
                if (d === 0) return 291;
                // Scale the value to fit between 10px and max height
                const minHeight = 10;
                const maxHeight = 291;
                const heightRange = maxHeight - minHeight;
                const scaledHeight = minHeight + (heightRange * (d / yAxisTop));
                return 291 - scaledHeight;
            })
            .attr("height", function (d) {
                if (d === 0) return 0;
                // Scale the value to fit between 10px and max height
                const minHeight = 10;
                const maxHeight = 291;
                const heightRange = maxHeight - minHeight;
                const scaledHeight = minHeight + (heightRange * (d / yAxisTop));
                return scaledHeight;
            })
            .attr("width", barWidth)
            .attr("fill", "#005ea2")
            .attr("rx", 0)
            .attr("ry", 0)
            .attr("stroke", "#FFFFFF")
            .attr("strokeWidth", 2)
            .on("mouseover", (event, d) => handleTooltip(event, d, false))
            .on("mouseout", (event) => {
                d3.select(event.currentTarget)
                    .style("opacity", "1");
                d3.select("div.highcharts-tooltip")
                    .style("left", "387px")
                    .style("top", "-9999px");
            });

        // National data bars
        if (showNational && reportNational) {
            series
                .append("g")
                .attr("class", "highcharts-series highcharts-tracker")
                .attr("visibility", "visible")
                .attr("zindex", 0.1)
                .attr("transform", "translate(51, 7.5) scale(1 1)")
                .selectAll("rect")
                .data([
                    returnDecimalNum(reportNational.DataRows[1].replace("%", "") * 1.0),
                    returnDecimalNum(reportNational.DataRows[2].replace("%", "") * 1.0),
                    returnDecimalNum(reportNational.DataRows[3].replace("%", "") * 1.0),
                    returnDecimalNum(reportNational.DataRows[4].replace("%", "") * 1.0)
                ])
                .enter()
                .append("rect")
                .attr("data-index", (d, i) => i)
                .attr("x", function (d, index) {
                    return 38 + barWidth + barSpacing + 145 * index;
                })
                .attr("y", function (d) {
                    if (d === 0) return 291;
                    // Scale the value to fit between 10px and max height
                    const minHeight = 10;
                    const maxHeight = 291;
                    const heightRange = maxHeight - minHeight;
                    const scaledHeight = minHeight + (heightRange * (d / yAxisTop));
                    return 291 - scaledHeight;
                })
                .attr("height", function (d) {
                    if (d === 0) return 0;
                    // Scale the value to fit between 10px and max height
                    const minHeight = 10;
                    const maxHeight = 291;
                    const heightRange = maxHeight - minHeight;
                    const scaledHeight = minHeight + (heightRange * (d / yAxisTop));
                    return scaledHeight;
                })
                .attr("width", barWidth)
                .attr("fill", "#73b3e7")
                .attr("rx", 0)
                .attr("ry", 0)
                .attr("stroke", "#FFFFFF")
                .attr("strokeWidth", 2)
                .on("mouseover", (event, d) => handleTooltip(event, d, true))
                .on("mouseout", (event) => {
                    d3.select(event.currentTarget)
                        .style("opacity", "1");
                    d3.select("div.highcharts-tooltip")
                        .style("left", "387px")
                        .style("top", "-9999px");
                });
        }

        // Update legend
        var legend = svg
            .append("g")
            .attr("class", "highcharts-legend")
            .attr("zindex", 7)
            .attr("transform", "translate(301, 366)")
            .append("g")
            .attr("zindex", 1);

        // Clinic legend item
        var clinicItem = legend
            .append("g")
            .attr("class", "highcharts-legend-item")
            .attr("zindex", 1)
            .attr("transform", showNational ? "translate(-50, -15)" : `translate(8, ${this.state.currentTab === 4 ? "0" : "-15"})`);

        clinicItem
            .append("text")
            .attr("x", 21)
            .attr("y", 17)
            .attr(
                "style",
                "color: Black; font-size: 14px; font-weight: bold; font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif; fill: Black;"
            )
            .attr("text-anchor", "start")
            .text(sessionStorage.getItem('ClinicId') === '9999' ? "National" : "Clinic");

        clinicItem
            .append("rect")
            .attr("x", 0)
            .attr("y", 6)
            .attr("width", 16)
            .attr("height", 12)
            .attr("fill", "#005ea2");

        // National legend item (only if showNational is true)
        if (showNational) {
            var nationalItem = legend
                .append("g")
                .attr("class", "highcharts-legend-item")
                .attr("zindex", 1)
                .attr("transform", "translate(50, -15)");

            nationalItem
                .append("text")
                .attr("x", 21)
                .attr("y", 17)
                .attr(
                    "style",
                    "color: Black; font-size: 14px; font-weight: bold; font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif; fill: Black;"
                )
                .attr("text-anchor", "start")
                .text("National");

            nationalItem
                .append("rect")
                .attr("x", 0)
                .attr("y", 6)
                .attr("width", 16)
                .attr("height", 12)
                .attr("fill", "#73b3e7");
        }
        // clinic data end

        series
            .append("g")
            .attr("class", "highcharts-markers")
            .attr("visibility", "visible")
            .attr("zindex", 0.1)
            .attr("transform", "translate(75, 10) scale(1 1)")
            .attr("clipPath", "none");



        svg.append("g")
            .attr("class", "highcharts-axis-labels highcharts-xaxis-labels four")
            .attr("zindex", 7)
            .selectAll("text")
            .data([
                row1header1,
                row1header2,
                row1header3,
                row1header4
            ])
            .enter()
            .append("text")
            .attr("x", function (d, index) {
                return 122 + index * 147;
            })
            .attr("y", 323)
            .attr("text-anchor", "middle")
            .attr("transform", "translate(0, 0)")
            .attr("style", "color: #333333; cursor: default; font-size: 14px; font-weight: normal; font-family: Helvetica, Arial, sans-serif; fill: #333333;")
            .attr("opacity", 1)
            .each(function (d) {
                const phrase = "Fresh Embryos";
                const phraseIndex = d.indexOf(phrase);
                if (phraseIndex !== -1) {
                    // Split the text at the end of the phrase
                    const firstPart = d.substring(0, phraseIndex + phrase.length);
                    const secondPart = d.substring(phraseIndex + phrase.length);
                    // Append first line
                    d3.select(this).append("tspan")
                        .attr("x", d3.select(this).attr("x"))
                        .text(firstPart);
                    // Append second line
                    if (secondPart) {
                        d3.select(this).append("tspan")
                            .attr("x", d3.select(this).attr("x"))
                            .attr("dy", "1.2em")
                            .text(secondPart);
                    }
                } else {
                    // For texts without the phrase, append as is
                    d3.select(this).text(d);
                }
            });

        svg
            .append("g")
            .attr("class", "highcharts-axis-labels highcharts-xaxis-labels five")
            .attr("zindex", 7)

            .selectAll("text")
            .data([
                row2header1,
                row2header2,
                row2header3,
                row2header4
            ])
            .enter()

            .append("text")
            .attr("x", function (d, index) {
                return 145 + index * 147;
            })
            .attr("y", 323 + 20)
            .attr("text-anchor", "middle")
            .attr("transform", "translate(0, 0)")
            .attr(
                "style",
                "color: #333333; cursor: default; font-size: 14px; font-weight: normal; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("opacity", 1)
            .text(function (d) {
                return d;
            });
        svg
            .append("g")
            .attr("class", "highcharts-axis-labels highcharts-yaxis-labels")
            .attr("zindex", 7)

            .selectAll("text")
            .data(yAxisLabels)
            .enter()

            .append("text")
            .attr("x", 56)
            .attr("y", function (d, index) {
                return 306 - index * yAxisSpacing; // 48.5; spacing between Y axis text
            })
            .attr("text-anchor", "end")
            .attr("transform", "translate(0, 0)")
            .attr(
                "style",
                "width: 200px; color: #333333; cursor: default; font-size: 14px; font-weight: normal; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("opacity", 1)
            .text(function (d) {
                return d;
            });

        svg
            .append("g")
            .attr("class", "highcharts-tooltip")
            .attr("zindex", 8)
            .attr("style", "cursor: default; padding: 0; white-space: nowrap;")
            .attr("transform", "translate(96, -9999)")
            .attr("opacity", 0)

            .append("path")
            .attr("fill", "rgba(255, 255, 255, 0)")
            .attr(
                "d",
                "M 3 0 L 101 0 C 104 0 104 0 104 3 L 104 60 C 104 63 104 63 101 63 L 58 63 52 69 46 63 3 63 C 0 63 0 63 0 60 L 0 3 C 0 0 0 0 3 0"
            );
    }
    render() {
        const chartTitle = this.state.chartTitle || 'Vertical Bar Chart';
        return <svg className="Tab2BarChartVertical" width="668" height="400" ref={this.ref} role="img" aria-labelledby={chartTitle}></svg>;
    }
}

export default BarChart;
