import React from 'react';

const NavigationHeader = () => {
    const handleNationalDataClick = (e) => {
        e.preventDefault();
        sessionStorage.setItem('ClinicId', '9999');
        sessionStorage.setItem('Year', '0');

        //this is to determine if national data is clicked from module navigation or main navigation
        sessionStorage.setItem('NationalHeaderLinkClick', 'false');
        window.location.reload();
    };

    const handlePrintClick = (e) => {
        e.preventDefault();
        window.print();
    };

    const isNationalData = sessionStorage.getItem('ClinicId') === '9999' ? true : false,
        sessionClinicName = sessionStorage.getItem('ClinicName'),
        sessionNationalHeaderLinkClick = sessionStorage.getItem('NationalHeaderLinkClick'),
        isSessionNationalHeaderLinkClick = sessionNationalHeaderLinkClick === 'true' ? true : false;

    const handleModifySearchClick = (e) => {
        e.preventDefault();
        const clinicId = sessionStorage.getItem('ModifyClinicId');
        sessionStorage.setItem('ClinicId', clinicId);
        window.location.reload();
    };

    return (
        <div className="row w-100 mr-0 mb-3 navigationheader-wrapper d-print-none" style={isNationalData ? { marginTop: '-60px' } : {}}>
            <div className="col-5 ml-auto">
                {isNationalData && !isSessionNationalHeaderLinkClick &&
                    <div className="mb-2 mt-5">
                        <a href="/artclinics" onClick={handleModifySearchClick}>
                            <img alt="return arrow" className="mr-1" src="/images/DRH_ART.Images.back_blue_trans.png" />
                            Return to {sessionClinicName}
                        </a>
                    </div>
                }
                <div className={`border p-2 rounded ${ isNationalData && isSessionNationalHeaderLinkClick ? "mt-5" : ""}`}>
                    <a href="/artclinics" className={`text-decoration-none d-inline-block col-7 ${isNationalData ? "mb-0" : "mb-2"}`}>
                        <span className="fill-primary cdc-icon-search_01" style={{ fontSize: '20px' }}></span> Modify Search
                    </a>
                    <a
                        href="/"
                        className="text-decoration-none"
                        onClick={handlePrintClick}
                    >
                        <span className="fill-p cdc-icon-pdf" style={{ fontSize: '20px' }}></span> Save as PDF (.pdf)
                    </a>
                    {!isNationalData &&
                        <a
                            href="/"
                            className="text-decoration-none d-block col-12"
                            onClick={handleNationalDataClick}
                        >
                            <img
                                style={{ width: '30px' }}
                                src="images/state/Shared.Images.Blue.US.png"
                                alt=""
                                aria-hidden="true"
                            /> View National Data
                        </a>
                    }
                </div>
            </div>
        </div>
    );
};

export default NavigationHeader;