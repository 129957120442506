//method to update the URL and URL Parameters as the user make their selection under Modify Search
export const updateURLAndParams = (param, value, isInitialLoadComplete) => {
    if (!isInitialLoadComplete) return;
    const url = new URL(window.location);

    if (value) {
        url.searchParams.set(param, value);
    } else {
        url.searchParams.delete(param);
        // Clear related parameters based on search type
        if (param === 'State') {
            url.searchParams.delete('Zip');
            url.searchParams.delete('Distance');
        } else if (param === 'Zip') {
            url.searchParams.delete('State');
        }
    }
    window.history.pushState({}, '', url);
};