import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { Spinner } from "../spinner/Spinner";
import { Modal, Button } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import FocusLock from 'react-focus-lock';
import { Wrapper } from "@googlemaps/react-wrapper";
import { useSetTitle } from "../../Utility";

function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumberString;
}

function isServiceItem(description) {
    return description.toLowerCase().includes('service');
}

//Table Component for services and profile
const TableComponent = ({ title, data, thSub, onQuestionClick, type, isNationalData }) => {
    //useSetTitle("Clinic Services & Profile", true)
    if (type === 'profile') {
        const profileData = data.filter(item => item[2] === 'Profile');

        // Group the entries by their main categories
        const groupedData = profileData.reduce((acc, item) => {
            const [fullName, value, type] = item;
            if (fullName.includes(' - ')) {
                const [mainCategory, subCategory] = fullName.split(' - ');
                if (!acc[mainCategory]) {
                    acc[mainCategory] = { value: '', subCategories: [] };
                }
                acc[mainCategory].subCategories.push({ name: subCategory, value });
            } else {
                acc[fullName] = { value, subCategories: [] };
            }
            return acc;
        }, {});



        return (
            <>
                < table className="table tabOne table-bordered is-hoverable is-fullwidth blue_title small-font" >
                    <thead>
                        <tr>
                            <th className="bg-primary">
                                <div className="table_title p-0 border-0 bg-primary d-flex align-items-center">
                                    <span>{title}</span>
                                    <button
                                        className="button js-modal-trigger question_btn ms-0 d-print-none"
                                        data-target="modal_help_1"
                                        aria-haspopup="dialog"
                                        aria-label={`Learn more about ${type === 'service' ? 'services' : 'profile items'}`}
                                        onClick={() => onQuestionClick(type)}
                                    >
                                        <span aria-hidden="true">?</span>
                                    </button>
                                </div>
                            </th>
                            <th className="bg-primary">
                                {thSub}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(groupedData).map(([category, { value, subCategories }]) => (
                            <React.Fragment key={category}>
                                <tr>
                                    <td className={isNationalData ? '' : ''}>{category}</td>
                                    <td className="text-center">{value}</td>
                                </tr>
                                {subCategories.map((sub, index) => (
                                    <tr key={`${category}-${index}`}>
                                        <td className="pl-4">{sub.name}</td>
                                        <td className="text-center">{sub.value}</td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table >
            </>
        );
    }

    // Service table rendering
    const serviceData = data.filter(item => item[2] === 'Service');
    return (
        <table className="table tabOne table-bordered is-hoverable is-fullwidth blue_title small-font">
            <thead>
                <tr>
                    <th className="bg-primary">
                        <div className="table_title p-0 border-0 bg-primary">
                            {title}
                            <button
                                className="button js-modal-trigger question_btn d-print-none"
                                data-target="modal_help_1"
                                aria-haspopup="true"
                                aria-label="Services description"
                                onClick={() => onQuestionClick(type)}
                            >
                                ?
                            </button>
                        </div>
                    </th>
                    <th className="bg-primary">
                        {thSub}
                    </th>
                </tr>
            </thead>
            <tbody>
                {serviceData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        <td>{row[0]}</td>
                        <td className="text-center">{row[1]}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

function Tab0({ user, actions, year, tables, isLoading, isArtDashboard, reportingYear, clinicStatus, footnotes, footnotesStatus, googleMapKey }) {
    const sessionClinicId = sessionStorage.getItem('ClinicId'),
        isNationalData = sessionClinicId === '9999';

    //set overlay for googlemap, tweak the UI here
    class InfoBox extends (window.google?.maps?.OverlayView || class { }) {
        constructor(content, position, map) {
            if (window.google?.maps?.OverlayView) {
                super();
                this.content = content;
                this.position = position;
                try {
                    if (map) this.setMap(map); // Ensures map is valid
                } catch (e) {
                    //supress
                }
            } else {
                console.error("Google Maps OverlayView is not available.");
            }
        }

        onAdd() {
            this.div = document.createElement("div");
            this.div.style.position = "absolute";
            this.div.style.backgroundColor = "white";
            this.div.style.padding = "10px";
            this.div.style.boxShadow = "0 2px 6px rgba(0, 0, 0, 0.3)";
            this.div.style.borderRadius = "4px";
            this.div.style.fontWeight = "500";
            this.div.style.fontSize = "15px";
            this.div.style.maxWidth = "250px"; // Set max width to 250px
            this.div.style.width = "auto"; // Let the width adjust dynamically

            this.div.innerHTML = this.content;

            const panes = this.getPanes();
            if (panes) {
                panes.floatPane.appendChild(this.div);
            }
        }


        draw() {
            if (!this.div) return;
            const projection = this.getProjection();
            const position = projection.fromLatLngToDivPixel(this.position);

            const divWidth = this.div.offsetWidth || 0;
            const divHeight = this.div.offsetHeight || 0;

            // Define a consistent gap between the pin and the InfoBox
            const pinHeight = 30; // Adjust based on the actual height of the pin
            const gapAbovePin = 10; // Add a gap above the pin

            // Position the InfoBox centered horizontally and dynamically above the pin
            this.div.style.left = `${position.x - divWidth / 2}px`;
            this.div.style.top = `${position.y - divHeight - pinHeight - gapAbovePin}px`;
        }

        onRemove() {
            if (this.div) {
                this.div.parentNode.removeChild(this.div);
                this.div = null;
            }
        }
    }

    //google map component info to display pin and overlay
    const MapComponent = ({ clinicInfo }) => {
        const mapRef = useRef(null);

        useEffect(() => {
            if (!clinicInfo || !clinicInfo.GeoLocation_Lat || !clinicInfo.GeoLocation_Long) {
                console.warn("Clinic info is incomplete or missing.");
                return;
            }

            if (!window.google || !window.google.maps || !window.google.maps.OverlayView) {
                console.error("Google Maps API is not loaded or OverlayView is unavailable.");
                return;
            }

            const map = new window.google.maps.Map(mapRef.current, {
                center: {
                    lat: parseFloat(clinicInfo.GeoLocation_Lat),
                    lng: parseFloat(clinicInfo.GeoLocation_Long),
                },
                zoom: 15,
                streetViewControl: false,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    position: window.google.maps.ControlPosition.LEFT_BOTTOM,
                    style: window.google.maps.MapTypeControlStyle.DEFAULT,
                    mapTypeIds: [
                        window.google.maps.MapTypeId.ROADMAP,
                        window.google.maps.MapTypeId.SATELLITE
                    ]
                },
                fullscreenControl: false,
                zoomControl: true,
                zoomControlOptions: {
                    position: window.google.maps.ControlPosition.RIGHT_CENTER
                },
                // Enable directions control
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                draggableCursor: 'default',
                draggingCursor: 'pointer',
                navigationControl: true
            });

            // Add CSS to adjust google map control position
            const style = document.createElement('style');
            style.textContent = `
               .gmnoprint.gm-style-mtc-bbw {
                   top: 393px !important;
                   z-index: 20000000 !important;
               }
               .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
                    top: 240px !important;
                }
               .gmnoprint.gm-bundled-control {
                    top: 20rem !important;
                }
            `;
            document.head.appendChild(style);


            new window.google.maps.Marker({
                position: {
                    lat: parseFloat(clinicInfo.GeoLocation_Lat),
                    lng: parseFloat(clinicInfo.GeoLocation_Long),
                },
                map,
            });

            const infoBox = new InfoBox(
                `${clinicInfo.FacilityName}`,
                new window.google.maps.LatLng(
                    parseFloat(clinicInfo.GeoLocation_Lat),
                    parseFloat(clinicInfo.GeoLocation_Long)
                ),
                map
            );

            setTimeout(() => { }, 1200); //delay to allow load of map

            // Cleanup on unmount
            return () => {
                try {
                    infoBox.setMap(null);
                } catch (e) {
                    //supress
                }
            };
        }, [clinicInfo]);

        return (
            <>
                <p className="font-weight-bold mb-0">Map</p>
                <div
                    ref={mapRef}
                    style={{
                        width: "70%",
                        height: "450px",
                        border: "1px solid #ddd"
                    }}
                />
            </>
        );
    };

    const GoogleMap = ({ clinicInfo }) => (
        <Wrapper apiKey={googleMapKey}>
            <MapComponent clinicInfo={clinicInfo} />
        </Wrapper>
    );

    const [modalState, setModalState] = useState({
        isVisible: false,
        type: null // 'service' or 'profile'
    });

    const [visibleSummaryMeta, setVisibleSummaryMeta] = useState(false);

    // For services/profile modal
    const handleModalClose = () => {
        setModalState({ isVisible: false, type: null });
        document.body.removeAttribute('aria-hidden');
    };

    const handleQuestionClick = (type) => {
        setModalState({ isVisible: true, type });
        document.body.setAttribute('aria-hidden', 'true');
    };

    // For summary modal
    const btnCloseSummaryMeta = () => {
        setVisibleSummaryMeta(false);
        document.body.removeAttribute('aria-hidden');
    };

    const btnShowSummaryMeta = () => {
        setVisibleSummaryMeta(true);
        document.body.setAttribute('aria-hidden', 'true');
    };

    useEffect(() => {
        return () => {
            document.body.removeAttribute('aria-hidden');
        };
    }, []);

    //const getGoogleMapsUrl = (clinicInfo) => {
    //    const lat = clinicInfo.GeoLocation_Lat;
    //    const long = clinicInfo.GeoLocation_Long;
    //    const address = `${clinicInfo.Address}, ${clinicInfo.City}, ${clinicInfo.StateDesc} ${clinicInfo.ZipCode}`;
    //    const encodedAddress = encodeURIComponent(address);
    //    return `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.2444!2d${long}!3d${lat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s!2s${encodedAddress}!5e0!3m2!1sen!2sus!4v1698085264!5m2!1sen!2sus`;
    //};

    const hasClinicInfo = Boolean(
        tables &&
        tables.ClinicInfo &&
        typeof tables.ClinicInfo === 'object' &&
        Object.keys(tables.ClinicInfo).length > 0
    );

    const servicesData = Array.isArray(tables?.Services) ? tables.Services : [];

    const services = servicesData
        .filter(item => Array.isArray(item) && item[2] === "Service");
    const profile = servicesData
        .filter(item => Array.isArray(item) && item[2] === "Profile");

    if (isLoading) {
        return (
            <div>
                <Spinner
                    message="Loading Data..."
                    backGround={true}
                    center={true}
                />
            </div>
        )
    }

    const getModalMetadata = () => {
        if (!tables.ServicesMetadata) return [];

        return tables.ServicesMetadata.filter(item => {
            const isService = isServiceItem(item[1]); // Check description text
            return modalState.type === 'service' ? isService : !isService;
        });
    };

    const baseFootnoteStyle = {
        position: 'relative',
        display: 'inline-block',
        top: '0px'
    };

    const normalStyle = {
        ...baseFootnoteStyle,
        left: '-4px',
        fontSize: '20px'
    };

    const modifiedStateStyle = {
        ...baseFootnoteStyle,
        fontSize: '14px',
        top: '-10px'
    };

    const reorganizedStyle = {
        ...modifiedStateStyle,
        color: '#some-color',
        fontStyle: 'italic'
    };

    const closedStyle = {
        ...modifiedStateStyle,
        color: '#another-color',
        textDecoration: 'line-through'
    };

    const footnoteStyles = {
        clinicName: {
            None: { ...normalStyle },
            Reorganized: {
                ...reorganizedStyle,
                left: '1px'
            },
            Closed: {
                ...closedStyle,
                left: '1px'
            }
        },
        info: {
            None: { ...normalStyle },
            Reorganized: {
                ...reorganizedStyle,
                marginRight: '3px'
            },
            Closed: {
                ...closedStyle,
                marginRight: '3px'
            }
        }
    };

    const Footnote = ({ type, symbol, text }) => {
        const style = footnoteStyles[type][footnotesStatus];
        const Element = type === 'clinicName' ? 'span' : 'sup';

        return (
            <>
                <Element
                    className={`footnote_${type === 'clinicName' ? 'ClinicName' : 'Info'}${footnotesStatus}`}
                    style={style}
                >
                    {symbol}
                </Element>
                {text && text}
            </>
        );
    };

    const renderFootnote = (type, includeText = true) => {
        if (!footnotes || footnotes.length === 0) return null;
        return (
            <Footnote
                type={type}
                symbol={footnotes[0].Symbol}
                text={includeText ? footnotes[0].FootNote : null}
            />
        );
    };

    return (
        <div id="pdfDiv">
            <div className="tab_in pt-2 px-5 tabOne">
                {/* Services/Profile Modal */}
                <Modal
                    show={modalState.isVisible}
                    onHide={handleModalClose}
                    className="metaDataTableModal"
                    aria-labelledby="metaDataTableModal"
                    backdrop="static"
                    keyboard={true}
                    centered
                >
                    <FocusLock returnFocus disabled={!modalState.isVisible}>
                        <Modal.Header>
                            <Modal.Title id="metaDataTableModal">
                                Description of {modalState.type === 'service' ? 'Services' : 'Profile'}
                            </Modal.Title>
                            <Button
                                onClick={handleModalClose}
                                className="bg-transparent border-0 text-dark"
                                aria-label={`Close ${modalState.type === 'service' ? 'services' : 'profile'} description modal`}
                            >
                                <span aria-hidden="true">X</span>
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            <table>
                                <thead>
                                    <tr>
                                        <th className="h5 py-3">{modalState.type === 'service' ? 'Service' : 'Profile Item'}</th>
                                        <th className="h5 py-3">Description</th>
                                    </tr>
                                </thead>
                                <tbody className="table table-bordered bl-0">
                                    {getModalMetadata().map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            <td className="small">{row[0]}</td>
                                            <td className="small">{row[1]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleModalClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </FocusLock>
                </Modal>
                {/* Summary Modal */}
                <Modal
                    show={visibleSummaryMeta}
                    onHide={btnCloseSummaryMeta}
                    className="metaDataTableModal"
                    aria-labelledby="metaDataTableModalTwo"
                    backdrop="static"
                    keyboard={true}
                    centered
                >
                    <FocusLock returnFocus disabled={!visibleSummaryMeta}>
                        <Modal.Header>
                            <Modal.Title id="metaDataTableModalTwo">Description of Clinic Summary</Modal.Title>
                            <Button
                                onClick={btnCloseSummaryMeta}
                                className="bg-transparent border-0 text-dark"
                                aria-label="Close clinic summary modal"
                            >
                                <span aria-hidden="true">X</span>
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            <table>
                                <thead>
                                    <tr>
                                        <th className="h5 py-3">Service</th>
                                        <th className="h5 py-3">Description</th>
                                    </tr>
                                </thead>
                                <tbody className="table table-bordered bl-0">
                                    {tables.ProfilesMetadata && tables.ProfilesMetadata.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {row.slice(0, 2).map((col, index) => (
                                                <td key={index} className="small">{col}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={btnCloseSummaryMeta}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </FocusLock>
                </Modal>

                <div className="columns is-variable bd-klmn-columns is-1 row ">
                    <div className={`column is-one-third ${isNationalData ? "col-12 mb-2 tabOneNat_ClinicInfo" : "col-4 tabOne_ClinicInfo"}`}>
                        <p className={`is-size-4 mb-0 ${isNationalData ? "" : "font-weight-bold"}`}>
                            {tables.ClinicInfo && tables.ClinicInfo.FacilityName}{footnotes && footnotes.length > 0 && <>{renderFootnote('clinicName', false)}</>}
                        </p>
                        {!isNationalData &&
                            <>
                                <p className="is-size-6 mb-0 small-font">{tables.ClinicInfo && tables.ClinicInfo.Address}</p>
                                <p className="is-size-6 mb-0 small-font">{tables.ClinicInfo && tables.ClinicInfo.City}, {tables.ClinicInfo && tables.ClinicInfo.StateDesc} {tables.ClinicInfo && tables.ClinicInfo.ZipCode}</p>
                                <p className="is-size-6 mb-0 small-font">Phone: {tables.ClinicInfo && formatPhoneNumber(tables.ClinicInfo.Phone)}</p>
                                <p>&nbsp;</p>
                                {clinicStatus !== "None" &&
                                    <>
                                        <p className="is-size-5 mb-0 font-weight-bold">Medical Director</p>
                                        <p className="is-size-6 mb-0 small-font">{tables.ClinicInfo && tables.ClinicInfo.MedicalDirector}</p>
                                        {footnotes && footnotes.length > 0 && <>
                                            <span className="d-block mt-2">{renderFootnote('info')}
                                                {clinicStatus === "Closed" && (
                                                    <> Contact the NASS Help Desk for current information about this clinic at 1-888-650-0822 or <a href="mailto:nass@westat.com">nass@westat.com</a>.</>
                                                )}</span>
                                        </>}

                                    </>}
                            </>
                        }
                    </div>
                    {clinicStatus === "None" && footnotes && footnotes.length > 0 && (
                        <div className="alert-info mb-3 p-3">
                            {renderFootnote('info')}
                        </div>
                    )}
                    {clinicStatus !== "None" &&
                        <>
                            <div className={`${isNationalData ? "col-8 row" : "col-4"}`}>
                                <div className={`${isNationalData ? "col-6 tableOneNat_ServiceProfile_table" : "tableOne_ServiceProfile_table"} `}>
                                    <TableComponent
                                        title={`${isNationalData ? "National" : "Clinic"}  Services`}
                                        data={services}
                                        thSub={`${isNationalData ? "Percentage" : "Provided"}`}
                                        onQuestionClick={handleQuestionClick}
                                        type="service"
                                        isNationalData={isNationalData}
                                    />
                                </div>
                                <div className={`${isNationalData ? "col-6 tabOneNat_tableProfile" : "tabOne_tableProfile"} `}>
                                    <TableComponent
                                        title={`${isNationalData ? "National" : "Clinic"} Profile`}
                                        data={profile}
                                        thSub={`${isNationalData ? "Percentage" : "Verified"}`}
                                        onQuestionClick={handleQuestionClick}
                                        type="profile"
                                        isNationalData={isNationalData}
                                    />
                                </div>
                            </div>

                            <div className="column col-4">
                                {/* Clinic Summary Table */}
                                <table className={`${isNationalData ? "tabOneNat_tableSummary" : "tabOne_tableSummary"} table table-bordered is-hoverable is-fullwidth blue_title small-font`}>
                                    <thead>
                                        <tr>
                                            <th className="bg-primary">
                                                <div className="table_title p-0 border-0 bg-primary">
                                                    {year !== "0" && `${year} `} {reportingYear} {isNationalData ? " National " : "Clinic"}  Summary
                                                    <button
                                                        className="button js-modal-trigger question_btn d-print-none"
                                                        data-target="modal_help_2"
                                                        aria-haspopup="dialog"
                                                        aria-label="Learn more about clinic summary"
                                                        onClick={btnShowSummaryMeta}
                                                    >
                                                        <span aria-hidden="true">?</span>
                                                    </button>
                                                </div>
                                            </th>
                                            <th className="bg-primary">
                                                <div className="table_title">Number</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tables.Services && tables.Profile.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {row.map((col, index) => {
                                                    // Function to format value based on if it's a number or string
                                                    const formatValue = (value) => {
                                                        const num = Number(value);
                                                        return !isNaN(num) ? num.toLocaleString('en-US') : value;
                                                    };

                                                    if (rowIndex === 1) {
                                                        if (index === 0) {
                                                            return <td key={index} className="pl-4">{col}</td>;
                                                        } else {
                                                            return <td className="text-center" key={index}>{formatValue(col)}</td>;
                                                        }
                                                    } else {
                                                        if (index === 0) {
                                                            return <td key={index}><b>{col}</b></td>;
                                                        } else {
                                                            return <td className="text-center" key={index}><b>{formatValue(col)}</b></td>;
                                                        }
                                                    }
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    }
                </div>

                {sessionClinicId !== "9999" && isArtDashboard && hasClinicInfo &&
                    <div className="map_block">
                        <div style={{ position: 'relative' }}>
                            <GoogleMap clinicInfo={tables.ClinicInfo} />
                            <button
                                onClick={() => {
                                    const address = `${tables.ClinicInfo.Address}, ${tables.ClinicInfo.City}, ${tables.ClinicInfo.StateDesc} ${tables.ClinicInfo.ZipCode}`;
                                    window.open(`https://www.google.com/maps/dir//${encodeURIComponent(address)}/@${tables.ClinicInfo.GeoLocation_Lat},${tables.ClinicInfo.GeoLocation_Long},16z`, '_blank');
                                }}
                                className="btn btn-primary d-print-none"
                                style={{
                                    position: 'absolute',
                                    left: '1%',
                                    top: '37px',
                                    right: '31%',
                                    width: '150px',
                                    zIndex: 1
                                }}
                            >
                                Get Directions
                            </button>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tab0);