import './App.css';
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
// import {connect} from 'react-redux'
// import {setUserObjAction} from './actions'

import "./styles/style.css";

import { CDCHeader, CDCSiteTitle, CDCBody, CDCFooter } from '@cdcent/templatepackage-react';
// This includes the built CSS files
import '@cdcent/templatepackage-react/assets/css/bootstrap.min.css';
import '@cdcent/templatepackage-react/assets/css/app.min.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import "../node_modules/font-awesome/css/font-awesome.css";
import "./index.scss";
import TabsPage from "./components/TabsPage"
import SamsCallBack from "./components/SamsCallBack"
import TabsPageWithSams from './components/TabsPageWithSams';
import { SkipNav } from './components/SkipNav';
import { LoggedOut } from './components/LoggedOut';
import ArtClinics from './components/artdashboard/ArtClinics';
import { IsArtDashboard } from './api/index';
import { Helmet } from 'react-helmet';
import NavBar from './components/artdashboard/NavBar/NavBar';
import { trigger_interaction, trigger_pageview } from './Utility'

//import {RedirectToSams} from './api'


function App({ user, actions }) {
    //set variable to use for Art Dashboard, Clinic Dashboard used a different clinicid
    const isArtDashboard = IsArtDashboard();
    let pageTitleName = IsArtDashboard() ? "Assisted Reproductive Technology (ART) Success Rates" : "NASS Clinic Dashboard";

    // Check if the app is in an iframe
    useEffect(() => {
        if (window.top !== window.self) {
            // Redirect or show an error
            window.top.location = window.self.location; // Redirect to the main site
            alert("Access Denied");
        } else {
            console.log('Loading App...')
        }
    }, []);

    return (
        <div>
            <SkipNav />
            <CDCHeader search={true} title={pageTitleName} link={'https://www.cdc.gov/art/success-rates/index.html'}></CDCHeader>
            <div role="region">
                {/*<Helmet>*/}
                {/*    <title>{pageTitleName}</title>*/}
                {/*</Helmet>*/}
            </div>
            <Router basename="/">
                {isArtDashboard && <NavBar />}
                <Routes>
                    <Route exact path="/" element={<TabsPageWithSams />} />
                    <Route exact path="/test" element={<TabsPage />} />
                    <Route exact path="/samscallback" element={<SamsCallBack />} />
                    <Route exact path="/loggedout" element={<LoggedOut />} />
                    <Route exact path="/artclinics" element={<ArtClinics />} />
                </Routes>
            </Router>
            <div id="tpFooter" className="d-print-none">
                <CDCFooter />
            </div>
        </div>
    );
}

//export default App
const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
}; // .mapStateToProps

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App)
