import React from 'react';

export const LoggedOut = () => {
    document.title = "Logged out"
    //doAnalyticsWithTimeout({
    //    levels: ["Chronic", "DRH", "surveillance", "NASS"],
    //    AzEntry: null,
    //    channel: "DRH_NASS_CD"
    //});
    return (
        <div className="container">
            <h1>
                Due to 30 minutes of inactivity you have been logged out
            </h1>
            <p>Please <a href="/"> sign in </a> to use the application</p>
        </div>
    );
};
