import React, { Component } from "react";
import * as d3 from "d3";
import { returnDecimalNum } from "../../util";

class BarChart extends Component {
    constructor(props) {
        super(props);
        const clinicId = sessionStorage.getItem('ClinicId');
        this.state = {
            data: this.props.data,
            dataNational: this.props.dataNational,
            showNational: clinicId === '9999' ? false : (this.props.showNational || false)
        };
        this.ref = React.createRef();
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showNational !== this.props.showNational) {
            const clinicId = sessionStorage.getItem('ClinicId');
            this.setState({
                showNational: clinicId === '9999' ? false : this.props.showNational
            }, () => {
                d3.select(this.ref.current).selectAll("*").remove();
                this.init();
            });
        }
    }

    lineBreak(text, width, x) {
        text.each(function () {
            var el = d3.select(this);
            let words = el.text().split(" ");
            let wordsFormatted = [];

            let string = "";
            for (let i = 0; i < words.length; i++) {
                if (words[i].length + string.length <= width) {
                    string = string + words[i] + " ";
                } else {
                    wordsFormatted.push(string);
                    string = words[i] + " ";
                    el.attr("y", parseInt(el.attr("y")) - 8);
                }
            }
            wordsFormatted.push(string);

            el.text("");
            for (var i = 0; i < wordsFormatted.length; i++) {
                var tspan = el.append("tspan").text(wordsFormatted[i]);
                if (i > 0) tspan.attr("x", x).attr("dy", "20");
            }
        });
    }

    init() {
        const report = this.state.data;
        const reportNational = this.state.dataNational;
        const showNational = this.state.showNational;
        const barWidth = showNational ? 20 : 40;
        const barSpacing = showNational ? 10 : 0;

        var svg = this.ref.current;

        svg = d3
            .select(svg)
            .attr("version", "1.1")
            .attr(
                "style",
                "font-family: Arial, Helvetica, sans-serif; font-size: 12px;"
            );

        // Add Y-axis label (vertical text)
        svg.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", + 10)
            .attr("x", -350)
            .attr("dy", "1em")
            .attr("text-anchor", "middle")
            .attr("font-size", "14px")
            .attr("font-weight", "bold")
            .append("tspan")
            .text("Reason");

        // Add X-axis label
        svg.append("text")
            .attr("transform", "translate(" + (487) + " ," + (724) + ")")
            .attr("text-anchor", "middle")
            .attr("font-size", "14px")
            .attr("font-weight", "bold")
            .append("tspan")
            .text("Percentage (%)");

        const axesGroup = svg.append("g")
            .attr("class", "axes")
            .attr("transform", "translate(20, -20)");

        axesGroup.selectAll(".vertical-grid")
            .data([0, 20, 40, 60, 80, 100])
            .enter()
            .append("line")
            .attr("class", "vertical-grid")
            .attr("x1", (d, index) => 221 + index * 102)
            .attr("y1", 30)  // Start from top
            .attr("x2", (d, index) => 221 + index * 102)
            .attr("y2", 700) // End at x-axis
            .attr("stroke", "#C0C0C0")
            .attr("stroke-width", 1)

        // Add Y-axis line
        axesGroup.append("line")
            .attr("class", "y-axis-line")
            .attr("x1", 220)
            .attr("y1", 30)
            .attr("x2", 220)
            .attr("y2", 700)
            .attr("stroke", "#333333")
            .attr("stroke-width", 1);

        // Add X-axis line
        axesGroup.append("line")
            .attr("class", "x-axis-line")
            .attr("x1", 220)
            .attr("y1", 700)
            .attr("x2", 767)
            .attr("y2", 700)
            .attr("stroke", "#333333")
            .attr("stroke-width", 1);

        var series = svg
            .append("g")
            .attr("class", "highcharts-series-group barcharthorizontal")
            .attr("transform", "translate(10, 0)")  // Added translation
            .attr("zindex", 3);

        //check if we have national data and should show it
        const hasNationalData = reportNational && reportNational.DataRows && Array.isArray(reportNational.DataRows);

        // Only reorder national array if we have national data
        const reorderedNationalArray = hasNationalData ? report.DataRows.map(clinicRow => {
            return reportNational.DataRows.find(nationalRow => nationalRow[0] === clinicRow[0]);
        }) : [];

        // Create the combined data and reverse it, match logi
        const combinedData = report.DataRows.map((row, index) => ({
            month: row[0],
            clinicValue: returnDecimalNum(row[1].replace("%", "") * 1.0),
            nationalValue: hasNationalData ? returnDecimalNum(reorderedNationalArray[index][1].replace("%", "") * 1.0) : null
        })).reverse(); // Reverse the array here, match logi

        // Clinic data bars
        series
            .append("g")
            .attr("class", "highcharts-series highcharts-tracker")
            .attr("visibility", "visible")
            .attr("zindex", 0.1)
            .attr("transform", "translate(742.2, 676) rotate(90) scale(-1, 1) scale(1 1)")
            .selectAll("rect")
            .data(combinedData)
            .enter()
            .append("rect")
            .attr("data-index", (d, i) => i)
            .attr("x", (d, index) => 11 + 51 * index) //set spacing between bars
            .attr("y", function (d) {
                if (d.clinicValue === 0) return 511;
                const minHeight = 10;
                const maxHeight = 511;
                const heightRange = maxHeight - minHeight;
                const scaledHeight = minHeight + (heightRange * (d.clinicValue / 100));
                return 511 - scaledHeight;
            })
            .attr("height", function (d) {
                if (d.clinicValue === 0) return 0;
                const minHeight = 10;
                const maxHeight = 511;
                const heightRange = maxHeight - minHeight;
                const scaledHeight = minHeight + (heightRange * (d.clinicValue / 100));
                return scaledHeight;
            })
            .attr("width", barWidth)
            .attr("fill", "#005ea2")
            .attr("rx", 0)
            .attr("ry", 0)
            .attr("stroke", "#FFFFFF")
            .attr("strokeWidth", 2)
            .on("mouseover", (event, d) => {
                d3.select(event.currentTarget)
                    .style("opacity", "0.8");
                d3.select("div.highcharts-tooltip")
                    .style("top", (showNational ? 695 : 685) - parseInt(d3.select(event.currentTarget).attr("x")) - 32 + "px")
                    .style("left", parseInt(d3.select(event.currentTarget).attr("height")) + 425 + "px");
                d3.select("#tooltipText").html(
                    `${d.month}: ${d.clinicValue.toFixed(1)}`
                );
            })
            .on("mouseout", (event) => {
                d3.select(event.currentTarget)
                    .style("opacity", "1");
                d3.select("div.highcharts-tooltip")
                    .style("left", "387px")
                    .style("top", "-9999px");
            });

        // National data bars (only if showNational is true)
        if (showNational) {
            series
                .append("g")
                .attr("class", "highcharts-series highcharts-tracker")
                .attr("visibility", "visible")
                .attr("zindex", 0.1)
                .attr("transform", "translate(742.2, 686) rotate(90) scale(-1, 1) scale(1 1)")
                .selectAll("rect")
                .data(combinedData)
                .enter()
                .append("rect")
                .attr("data-index", (d, i) => i)
                .attr("x", (d, index) => 11 + 51 * index)
                .attr("y", function (d) {
                    if (d.nationalValue === 0) return 511;
                    const minHeight = 10;
                    const maxHeight = 511;
                    const heightRange = maxHeight - minHeight;
                    const scaledHeight = minHeight + (heightRange * (d.nationalValue / 100));
                    return 511 - scaledHeight;
                })
                .attr("height", function (d) {
                    if (d.nationalValue === 0) return 0;
                    const minHeight = 10;
                    const maxHeight = 511;
                    const heightRange = maxHeight - minHeight;
                    const scaledHeight = minHeight + (heightRange * (d.nationalValue / 100));
                    return scaledHeight;
                })
                .attr("width", barWidth)
                .attr("transform", `translate(${barWidth + barSpacing}, 0)`)
                .attr("fill", "#73b3e7")
                .attr("rx", 0)
                .attr("ry", 0)
                .attr("stroke", "#FFFFFF")
                .attr("strokeWidth", 2)
                .on("mouseover", (event, d) => {
                    const i = parseInt(d3.select(event.currentTarget).attr("data-index"));
                    d3.select(event.currentTarget)
                        .style("opacity", "0.8");
                    d3.select("div.highcharts-tooltip")
                        .style("top", 675 - parseInt(d3.select(event.currentTarget).attr("x")) - 32 + "px")
                        .style("left", parseInt(d3.select(event.currentTarget).attr("height")) + 425 + "px");
                    d3.select("#tooltipText").html(
                        `National ${combinedData[i].month}: ${combinedData[i].nationalValue.toFixed(1)}`
                    );
                })
                .on("mouseout", (event) => {
                    d3.select(event.currentTarget)
                        .style("opacity", "1");
                    d3.select("div.highcharts-tooltip")
                        .style("left", "387px")
                        .style("top", "-9999px");
                });
        }

        // X-axis labels (using combined data)
        svg
            .append("g")
            .attr("class", "highcharts-axis-labels highcharts-xaxis-labels three")
            .attr("zindex", 7)
            .selectAll("text")
            .data(combinedData)
            .enter()
            .append("text")
            .attr("x", 235)
            .attr("y", (d, index) => (showNational ? 634 : 625) - index * 51 + barWidth / 2)  // Added barWidth/2 for centering
            .attr("text-anchor", "end")
            .attr("dominant-baseline", "middle")
            .attr(
                "style",
                "width: 200px; color: #333333; cursor: default; font-size: 14px; font-weight: normal; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("opacity", 1)
            .text(d => d.month);

        // X-axis labels
        svg
            .append("g")
            .attr("class", "highcharts-axis-labels highcharts-yaxis-labels")
            .attr("transform", "translate(87, -20)")
            .attr("zindex", 7)
            .selectAll("text")
            .data([0, 20, 40, 60, 80, 100])
            .enter()
            .append("text")
            .attr("x", (d, index) => 145 + index * 102)
            .attr("y", 725)
            .attr("text-anchor", "middle")
            .attr(
                "style",
                "width: 200px; color: #333333; cursor: default; font-size: 14px; font-weight: normal; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("opacity", 1)
            .text(d => d);

        // Legend (clinic and national)
        var legend = svg
            .append("g")
            .attr("class", "highcharts-legend")
            .attr("zindex", 7)
            .attr("transform", "translate(460, 746)");

        var legendGroup = legend.append("g").attr("zindex", 1);

        // Clinic legend item
        var clinicItem = legendGroup
            .append("g")
            .attr("class", "highcharts-legend-item")
            .attr("zindex", 1)
            .attr("transform", showNational ? "translate(-50, -15)" : "translate(0, -15)");

        clinicItem
            .append("text")
            .attr("x", 21)
            .attr("y", 15)
            .attr(
                "style",
                "color: Black; font-size: 14px; font-weight: bold; font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif; fill: Black;"
            )
            .attr("text-anchor", "start")
            .text(sessionStorage.getItem('ClinicId') === '9999' ? "National" : "Clinic");

        clinicItem
            .append("rect")
            .attr("x", 0)
            .attr("y", 4)
            .attr("width", 16)
            .attr("height", 12)
            .attr("fill", "#005ea2");

        //add x-asix ticks
        axesGroup.selectAll(".x-axis-tick")
            .data([0, 20, 40, 60, 80, 100])
            .enter()
            .append("line")
            .attr("class", "x-axis-tick")
            .attr("x1", (d, index) => 221 + index * 102)
            .attr("y1", 702)
            .attr("x2", (d, index) => 221 + index * 102)
            .attr("y2", 708)
            .attr("stroke", "#333333")
            .attr("stroke-width", 2);

        // National legend item (only if showNational is true)
        if (showNational) {
            var nationalItem = legendGroup
                .append("g")
                .attr("class", "highcharts-legend-item")
                .attr("zindex", 1)
                .attr("transform", "translate(50, 3)");

            nationalItem
                .append("text")
                .attr("x", 21)
                .attr("y", - 2)
                .attr(
                    "style",
                    "color: Black; font-size: 14px; font-weight: bold; font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif; fill: Black;"
                )
                .attr("text-anchor", "start")
                .text("National");

            nationalItem
                .append("rect")
                .attr("x", 0)
                .attr("y", -14)
                .attr("width", 16)
                .attr("height", 12)
                .attr("fill", "#73b3e7");
        }
    }

    render() {
        return <svg width="755" height="750" ref={this.ref} role="img" aria-labelledby="horizontal chart"></svg>;
    }
}

export default BarChart;