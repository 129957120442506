import React from "react";
import { returnDecimalNum } from "../../util";

const TabularChart = (props) => {
  const { data, dataNational, dataRowsFormat } = props;
  const clinicId = sessionStorage.getItem('ClinicId');
  const showNational = clinicId === '9999' ? false : (props.showNational || false);

  // Helper function to handle empty values
  const formatValue = (value, usePercent = false) => {
    if (value === "" || value === undefined || value === null) {
      return "";
    }
    // Only call returnDecimalNum if we have a valid value
    return returnDecimalNum(value, usePercent);
  };

  return (
    <div className="table-container">
      <table className="table is-hoverable is-fullwidth table-bordered tabularChartOne" cellSpacing="1">
        <thead>
          <tr>
            <th className="th-tertiary" style={{ textAlign: "center", backgroundColor: "#e3d3e4" }}>
              <span className="sr-only">Data Type</span>
            </th>
            {data.RowHeaders.slice(1, 5).map((header, index) => (
              <th 
                key={index}
                className="th-tertiary" 
                style={{ textAlign: "center", backgroundColor: "#e3d3e4", width: "160px" }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="th-tertiary" style={{ backgroundColor: "#e3d3e4" }}>
              <b>{clinicId === '9999' ? "National" : "Clinic"}</b>
            </th>
            {dataRowsFormat === 'tab1' || dataRowsFormat === 'tab1NoPercent' ? (
              <>
                {[1, 2, 3, 4].map((index) => (
                  <td 
                    key={index}
                    style={{ textAlign: "center" }}
                    dangerouslySetInnerHTML={{ 
                      __html: formatValue(data.DataRows[0][index]) 
                    }}
                  />
                ))}
              </>
            ) : (
              <>
                {[1, 2, 3, 4].map((index) => (
                  <td 
                    key={index}
                    style={{ textAlign: "center" }}
                    dangerouslySetInnerHTML={{ 
                      __html: formatValue(data.DataRows[index], true) 
                    }}
                  />
                ))}
              </>
            )}
          </tr>
          {showNational && dataNational && (
            <tr>
              <th className="th-tertiary" style={{ backgroundColor: "#e3d3e4" }}>
                <b>National</b>
              </th>
              {dataRowsFormat === 'tab1' || dataRowsFormat === 'tab1NoPercent' ? (
                <>
                  {[1, 2, 3, 4].map((index) => (
                    <td 
                      key={index}
                      style={{ textAlign: "center" }}
                      dangerouslySetInnerHTML={{ 
                        __html: formatValue(dataNational.DataRows[0][index]) 
                      }}
                    />
                  ))}
                </>
              ) : (
                <>
                  {[1, 2, 3, 4].map((index) => (
                    <td 
                      key={index}
                      style={{ textAlign: "center" }}
                      dangerouslySetInnerHTML={{ 
                        __html: formatValue(dataNational.DataRows[index], true) 
                      }}
                    />
                  ))}
                </>
              )}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TabularChart;