import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import FocusLock from 'react-focus-lock';

const ClinicFilters = ({
    onFilterChange,
    availableServices = [],
    availableProfiles = [],
    serviceLabels = {},
    profileLabels = {},
    servicesMetadata = []
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedProfiles, setSelectedProfiles] = useState([]);
    const [modalConfig, setModalConfig] = useState({
        isVisible: false,
        type: null
    });

    // Modal handling functions
    const closeModal = () => {
        setModalConfig({ isVisible: false, type: null });
        document.body.removeAttribute('aria-hidden');
    };

    const showServiceMeta = () => {
        setModalConfig({ isVisible: true, type: 'services' });
        document.body.setAttribute('aria-hidden', 'true');
    };

    const showProfileMeta = () => {
        setModalConfig({ isVisible: true, type: 'profiles' });
        document.body.setAttribute('aria-hidden', 'true');
    };

    useEffect(() => {
        return () => {
            document.body.removeAttribute('aria-hidden');
        };
    }, []);

    // Event handlers 
    const toggleFilters = () => setIsVisible(!isVisible);

    const handleServiceChange = (service) => {
        const updatedServices = selectedServices.includes(service)
            ? selectedServices.filter(s => s !== service)
            : [...selectedServices, service];
        setSelectedServices(updatedServices);
        onFilterChange({
            text: searchText,
            services: updatedServices,
            profiles: selectedProfiles
        });
    };

    const handleProfileChange = (profile) => {
        const updatedProfiles = selectedProfiles.includes(profile)
            ? selectedProfiles.filter(p => p !== profile)
            : [...selectedProfiles, profile];
        setSelectedProfiles(updatedProfiles);
        onFilterChange({
            text: searchText,
            services: selectedServices,
            profiles: updatedProfiles
        });
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
        onFilterChange({
            text: e.target.value,
            services: selectedServices,
            profiles: selectedProfiles
        });
    };

    // Split services into columns
    const midPoint = Math.ceil(availableServices.length / 2);
    const firstColumnServices = availableServices.slice(0, midPoint);
    const secondColumnServices = availableServices.slice(midPoint);

    const getModalContent = () => {
        const isServices = modalConfig.type === 'services';
        let data;
        if (isServices) {
            data = servicesMetadata.filter(([service]) =>
                service !== "SART member" &&
                service !== "Verified lab accreditation"
            );
        } else {
            data = servicesMetadata.filter(([service]) =>
                service === "SART member" ||
                service === "Verified lab accreditation"
            );
        }

        return {
            title: isServices ? 'Description of Services' : 'Description of Profiles',
            data: data,
            headerText: isServices ? 'Service' : 'Profile'
        };
    };

    const modalContent = getModalContent();

    return (
        <div className="mb-4">
            {/* Modal remains largely the same */}
            <Modal
                show={modalConfig.isVisible}
                onHide={closeModal}
                backdrop="static"
                keyboard={true}
                centered
            >
                <FocusLock returnFocus disabled={!modalConfig.isVisible}>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>{modalContent.title}</Modal.Title>
                        <Button
                            onClick={closeModal}
                            className="modalCloseBtn"
                            aria-label="Close description modal"
                        >
                            <span aria-hidden="true">X</span>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <table aria-label={modalContent.title}>
                            <thead>
                                <tr>
                                    <th scope="col" className="h5 py-3">{modalContent.headerText}</th>
                                    <th scope="col" className="h5 py-3">Description</th>
                                </tr>
                            </thead>
                            <tbody className="table table-bordered">
                                {modalContent.data && modalContent.data.map((row, index) => (
                                    <tr key={index}>
                                        <th scope="row" className="small font-weight-bold">{row[0]}</th>
                                        <td className="small">{row[1]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={closeModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </FocusLock>
            </Modal>

            <button
                onClick={toggleFilters}
                className="btn btn-primary mb-3"
                aria-expanded={isVisible}
                aria-controls="clinic-filters"
            >
                {isVisible ? 'Hide Filters' : 'Show Filters'}
            </button>

            {isVisible && (
                <div id="clinic-filters" className="card border-0">
                    <div className="card-body">
                        <div className="mb-4">
                            <label htmlFor="clinic-search" className="form-label h5 font-weight-bold mr-3">
                                Filter by clinic name or city:
                            </label>
                            <input
                                id="clinic-search"
                                type="text"
                                value={searchText}
                                onChange={handleSearchChange}
                                placeholder="Search..."
                                className="control-input"
                            />
                        </div>

                        {availableServices.length > 0 && (
                            <fieldset className="mb-4">
                                <legend style={{ display: 'inline-block' }}>
                                    Filter by clinics that offer the selected services
                                    <button
                                        className="button btn-primary js-modal-trigger question_btn"
                                        style={{
                                            marginLeft: '4px',
                                            verticalAlign: 'middle',
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                        aria-label="Learn more about available services"
                                        onClick={showServiceMeta}
                                    >
                                        <span aria-hidden="true">?</span>
                                    </button>
                                </legend>
                                <div className="row pl-5">
                                    <div className="col-md-4">
                                        {firstColumnServices.map(serviceId => (
                                            <div className="form-check" key={serviceId}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`service-${serviceId}`}
                                                    checked={selectedServices.includes(serviceId)}
                                                    onChange={() => handleServiceChange(serviceId)}
                                                />
                                                <label className="form-check-label" htmlFor={`service-${serviceId}`}>
                                                    {serviceLabels[serviceId] || serviceId}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-md-6">
                                        {secondColumnServices.map(serviceId => (
                                            <div className="form-check" key={serviceId}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`service-${serviceId}`}
                                                    checked={selectedServices.includes(serviceId)}
                                                    onChange={() => handleServiceChange(serviceId)}
                                                />
                                                <label className="form-check-label" htmlFor={`service-${serviceId}`}>
                                                    {serviceLabels[serviceId] || serviceId}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </fieldset>
                        )}

                        {availableProfiles.length > 0 && (
                            <fieldset>
                                <legend style={{ display: 'inline-block' }}>
                                    Filter by clinics with selected profile:
                                    <button
                                        className="button btn-primary question_btn"
                                        style={{
                                            marginLeft: '4px',
                                            verticalAlign: 'middle',
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                        aria-label="Learn more about clinic profiles"
                                        onClick={showProfileMeta}
                                    >
                                        <span aria-hidden="true">?</span>
                                    </button>
                                </legend>
                                <div className="row pl-5">
                                    {availableProfiles.map(profileId => (
                                        <div className="col-md-4" key={profileId}>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`profile-${profileId}`}
                                                    checked={selectedProfiles.includes(profileId)}
                                                    onChange={() => handleProfileChange(profileId)}
                                                />
                                                <label className="form-check-label" htmlFor={`profile-${profileId}`}>
                                                    {profileLabels[profileId] || profileId}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </fieldset>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ClinicFilters;