//Component to show the results of your Clinic Search, it also includes the pagination component here

import React, { useState } from 'react';
import ClinicFilters from '../ClinicFilters/ClinicFilters';
import './ClinicResultsView.scss';
import Spinner from '../../spinner/Spinner';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import FocusLock from 'react-focus-lock';
import { useEffect } from 'react';
import { useRef } from 'react';
import { stateMapping } from '../Utility/stateUtils';



const ClinicResultsView = ({
    isLoading,
    selectedState,
    distance,
    zip,
    error,
    clinicData,
    currentPage,
    itemsPerPage,
    totalPages,
    handlePageChange,
    handleFilterChange,
    availableSubTopics,
    serviceLabels,
    availableProfiles,
    profileLabels,
    hasDistanceData,
    paginatedData,
    handleClinicClick,
    profilesMetadata,
    servicesMetadata = []
}) => {
    //console.log(paginatedData);
    const serviceButtonRef = useRef(null);
    const sartButtonRef = useRef(null);
    const labAccreditationButtonRef = useRef(null);

    // Add modal state management
    const [modalConfig, setModalConfig] = useState({
        isVisible: false,
        type: null
    });

    const showServiceMeta = () => {
        setModalConfig({
            isVisible: true,
            type: 'services'
        });
        document.body.setAttribute('aria-hidden', 'true');
    };

    const showProfileMeta = (buttonType) => {
        setModalConfig({
            isVisible: true,
            type: 'profiles',
            buttonType // Track whether it was SART or Lab Accreditation
        });
        document.body.setAttribute('aria-hidden', 'true');
    };

    const closeModal = () => {
        setModalConfig(prev => {
            // Return focus based on modal type and button type
            if (prev.type === 'services') {
                setTimeout(() => serviceButtonRef.current?.focus(), 0);
            } else if (prev.type === 'profiles') {
                if (prev.buttonType === 'sart') {
                    setTimeout(() => sartButtonRef.current?.focus(), 0);
                } else if (prev.buttonType === 'lab') {
                    setTimeout(() => labAccreditationButtonRef.current?.focus(), 0);
                }
            }
            return { isVisible: false, type: null, buttonType: null };
        });
        document.body.removeAttribute('aria-hidden');
    };

    // Get modal content based on type
    const getModalContent = () => {
        const isServices = modalConfig.type === 'services';

        let data;
        if (isServices) {
            data = servicesMetadata.filter(([service]) =>
                service !== "SART member" &&
                service !== "Verified lab accreditation"
            );
        } else {
            data = servicesMetadata.filter(([service]) =>
                service === "SART member" ||
                service === "Verified lab accreditation"
            );
        }

        return {
            title: isServices ? 'Description of Services' : 'Description of Profiles',
            data: data,
            headerText: isServices ? 'Service' : 'Profile'
        };
    };

    const modalContent = getModalContent();

    // Cleanup effect for aria-hidden
    useEffect(() => {
        return () => {
            // Remove aria-hidden from body on component unmount
            document.body.removeAttribute('aria-hidden');
        };
    }, []);

    // Helper function to separate facility name and footnote
    const separateNameAndFootnote = (facilityName) => {
        const spanMatch = facilityName.match(/<span.*?>(.*?)<\/span>/);
        let additionalClass = '';

        if (spanMatch) {
            const footnoteContent = spanMatch[1];

            // Check for symbols using character codes
            if (footnoteContent.charCodeAt(0) === 8224) {  // �
                additionalClass = 'clinicStatusNone';
            } else if (footnoteContent.charCodeAt(0) === 163) {  // �
                additionalClass = 'clinicStatusReorganized';
            } else if (footnoteContent.charCodeAt(0) === 167) {  // �
                additionalClass = 'clinicStatusClosed';
            }

            const nameWithoutSpan = facilityName.replace(/<span.*?>(.*?)<\/span>/, '').trim();
            return {
                name: nameWithoutSpan,
                footnote: spanMatch[0],
                className: additionalClass
            };
        }

        return {
            name: facilityName,
            footnote: null,
            className: additionalClass
        };
    };
    const ResultsTable = ({ paginatedData, hasDistanceData, handleClinicClick }) => (
        <>
            {/* Add Modal */}
            <Modal
                show={modalConfig.isVisible}
                onHide={closeModal}
                className="metaDataTableModal"
                size="lg"
                backdrop="static"
                keyboard={true}
                centered
            >
                <FocusLock disabled={!modalConfig.isVisible}>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>{modalContent.title}</Modal.Title>
                        <Button
                            onClick={closeModal}
                            className="modalCloseBtn"
                            aria-label="Close description modal"
                        >
                            <span aria-hidden="true">X</span>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <table
                            aria-label={modalContent.title}
                            role="table"
                        >
                            <caption className="sr-only">{modalContent.title}</caption>
                            <thead>
                                <tr>
                                    <th scope="col" className="h5 py-3">{modalContent.headerText}</th>
                                    <th scope="col" className="h5 py-3">Description</th>
                                </tr>
                            </thead>
                            <tbody className="table table-bordered">
                                {modalContent.data && modalContent.data.map((row, index) => (
                                    <tr key={index}>
                                        <th scope="row" className="small font-weight-bold">{row[0]}</th>
                                        <td className="small">{row[1]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={closeModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </FocusLock>
            </Modal>
            <table className="results-table table-bordered table table-striped mb-2">
                <thead className="bg-primary">
                    <tr>
                        <th className="table-header" style={{ width: "300px" }}>Clinic Name</th>
                        <th className="table-header">Address</th>
                        {hasDistanceData && (
                            <th className="table-header">Distance</th>
                        )}
                        <th className="table-header col-2" style={{ width: "160px" }}>Phone</th>
                        <th className="table-header" style={{ width: "320px" }}>
                            Services
                            <button
                                ref={serviceButtonRef}
                                className="bg-transparent border-0 p-0"
                                aria-haspopup="dialog"
                                aria-label="Learn more about available services"
                                onClick={showServiceMeta}
                            >
                                <span className="cdc-icon-question_02 fill-w ml-2 x-16" aria-hidden="true"></span>
                            </button>
                        </th>
                        <th className="table-header" style={{ width: "125px" }}>
                            SART Member
                            <button
                                ref={sartButtonRef}
                                className="bg-transparent border-0 p-0"
                                aria-haspopup="dialog"
                                aria-label="Learn more about SART membership"
                                onClick={() => showProfileMeta('sart')}
                            >
                                <span className="cdc-icon-question_02 fill-w ml-2 x-16" aria-hidden="true"></span>
                            </button>
                        </th>
                        <th className="table-header" style={{ width: "165px" }}>
                            Verified Lab Accreditation
                            <button
                                ref={labAccreditationButtonRef}
                                className="bg-transparent border-0 p-0"
                                aria-haspopup="dialog"
                                aria-label="Learn more about lab accreditation"
                                onClick={() => showProfileMeta('lab')}
                            >
                                <span className="cdc-icon-question_02 fill-w ml-2 x-16" aria-hidden="true"></span>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedData.map((clinic, index) => {
                        const { name, footnote, className } = separateNameAndFootnote(clinic.FacilityName);
                        return (
                            <tr key={index} className={`${className ? className : ''}`}>
                                <td className="table-cell">
                                    <Link
                                        to="/"
                                        onClick={(e) => {
                                            const footnoteContent = footnote ?
                                                footnote.replace(/<\/?[^>]+(>|$)/g, "") : null;
                                            sessionStorage.setItem('ClinicName', name);
                                            handleClinicClick(clinic.ArtClinicId, footnoteContent);
                                        }}
                                        aria-label={`View details for ${name}`}
                                        dangerouslySetInnerHTML={{ __html: name }}
                                    />
                                    {footnote && <span className={className} dangerouslySetInnerHTML={{ __html: footnote }} />}
                                </td>
                                <td className="table-cell font-small" dangerouslySetInnerHTML={{
                                    __html: `${clinic.Address} <br /> ${clinic.City}, ${clinic.StateAbbr} ${clinic.ZipCode}`
                                }} />
                                {hasDistanceData && (
                                    <td className="table-cell">
                                        {clinic.Distance && !isNaN(parseFloat(clinic.Distance)) ?
                                            `${parseFloat(clinic.Distance).toFixed(1)} miles` :
                                            'N/A'
                                        }
                                    </td>
                                )}
                                <td className="table-cell">{clinic.Phone}</td>
                                <td className="table-cell">{clinic.services}</td>
                                <td className="table-cell">{clinic.SART}</td>
                                <td className="table-cell">{clinic.accreditation}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );

    const PaginationNav = ({ currentPage, totalPages, onPageChange }) => {
        const getPageNumbers = () => {
            const pages = [];
            const maxVisiblePages = 5;

            if (totalPages <= maxVisiblePages) {
                for (let i = 1; i <= totalPages; i++) {
                    pages.push(i);
                }
            } else {
                pages.push(1);

                let start = Math.max(currentPage - 1, 2);
                let end = Math.min(start + 2, totalPages - 1);

                if (end === totalPages - 1) {
                    start = Math.max(end - 2, 2);
                }

                if (start > 2) {
                    pages.push('...');
                }

                for (let i = start; i <= end; i++) {
                    pages.push(i);
                }

                if (end < totalPages - 1) {
                    pages.push('...');
                }

                pages.push(totalPages);
            }

            return pages;
        };

        return (
            <nav aria-label="Clinic results pagination" className="">
                <ul className="pagination justify-content-end my-0">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button
                            className="page-link font-weight-bold"
                            onClick={() => onPageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            aria-label="Previous page"
                        >
                            <span aria-hidden="true">&lt;</span>
                        </button>
                    </li>
                    {getPageNumbers().map((page, index) => (
                        <li
                            key={index}
                            className={`page-item ${page === currentPage ? 'active' : ''} ${page === '...' ? 'disabled' : ''}`}
                        >
                            <button
                                className="page-link"
                                onClick={() => page !== '...' ? onPageChange(page) : null}
                                disabled={page === '...'}
                                aria-label={page === '...' ? 'More pages' : `Go to page ${page}`}
                                aria-current={page === currentPage ? 'page' : undefined}
                            >
                                {page}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button
                            className="page-link font-weight-bold"
                            onClick={() => onPageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            aria-label="Next page"
                        >
                            <span aria-hidden="true">&gt;</span>
                        </button>
                    </li>
                </ul>
            </nav>
        );
    };

    return (
        <div className="results-section px-0">
            {/* Always show filters */}
            {/*{console.log(selectedState)}*/}
            {!isLoading && (selectedState || zip) && (
                <>
                    <h2 className="text-primary mb-0">
                        {selectedState
                            ? `${selectedState} Clinics`
                            : `Zipcode ${zip} Clinics within ${distance} miles`
                        }
                    </h2>
                    <p>{clinicData?.length ?? 0} clinics found</p>
                </>
            )}
            <ClinicFilters
                onFilterChange={handleFilterChange}
                availableServices={availableSubTopics}
                availableProfiles={availableProfiles}
                serviceLabels={serviceLabels}
                profileLabels={profileLabels}
                servicesMetadata={servicesMetadata}
                profilesMetadata={profilesMetadata}
            />

            {isLoading && (
                <Spinner
                    message="Searching for Clinics..."
                    backGround={true}
                    center={true}
                />
            )}

            {error && (
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            )}

            {!isLoading && !error && clinicData && clinicData.length > 0 && (
                <>
                    {totalPages > 1 && (
                        <div className="row">
                            <p className="col-6">

                                {clinicData.length > 25 && <span> {Math.min(currentPage * itemsPerPage, clinicData.length)} of {clinicData.length} results</span>}
                            </p>
                            <div className="col-6">
                                <PaginationNav
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </div>
                    )}

                    <ResultsTable
                        paginatedData={paginatedData}
                        hasDistanceData={hasDistanceData}
                        handleClinicClick={handleClinicClick}
                    />

                    {totalPages > 1 && (
                        <PaginationNav
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />
                    )}
                </>
            )}

            {!isLoading && !error && (!clinicData || clinicData.length === 0) && (
                <div className="alert alert-info bg-white" role="alert">
                    <h3 className="alert-heading mt-0">No Results Found</h3>
                    <p>No clinics found matching your search criteria. </p>
                </div>
            )}


        </div>
    );
};

export default ClinicResultsView;