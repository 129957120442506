export function RedirectToSams(userObj) {
    //window.location.replace("https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize?client_id=bfab691b-91c3-4c1a-8ba4-3a6daf986a90&redirect_uri=http://localhost/SAMSCallback.aspx&response_type=code");
    // const navigate = useNavigate();

    // navigate("https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize?client_id=bfab691b-91c3-4c1a-8ba4-3a6daf986a90&redirect_uri=http://localhost/SAMSCallback.aspx&response_type=code");

    return userObj;
}

export function GetEnvironment() {
    var Environment = "prod";

    if (window.location.href.indexOf("localhost") > -1) {
        Environment = "localhost";
    }
    if (window.location.href.indexOf("-dev") > -1) {
        Environment = "dev";
    }
    if (window.location.href.indexOf("-test") > -1) {
        Environment = "qa";
    }
    if (window.location.href.indexOf("-int") > -1) {
        Environment = "int";
    }

    return Environment;
}

export function IsArtDashboard(){
    const hostname = window.location.hostname;

        // Valid hostnames for ArtDashboard
        const artDashBoardValidHostnames = [
            "art.apps.ecpaas-dev.cdc.gov",
            "art-qa.apps.ecpaas-dev.cdc.gov",
            "art-int.cdc.gov",
            "art.cdc.gov"
        ];

        return  artDashBoardValidHostnames.includes(hostname);
        //return true; //use this if you want to run art dashboard locally
}