import React, { Component } from "react";

import Tab0 from "./tabs/Tab0";
import Tab1 from "./tabs/Tab1";
import Tab2 from "./tabs/Tab2";
import Tab3 from "./tabs/Tab3";
import Tab4 from "./tabs/Tab4";

export default class TabsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { tabIndex: 0 };
    this.changeTabHandler = this.changeTabHandler.bind(this);
    this.currentTabHandler = this.currentTabHandler.bind(this);
  }

  changeTabHandler(index) {
    this.setState({ tabIndex: index });
  }

  currentTabHandler() {
    switch (this.state.tabIndex) {
      case 0:
        return <Tab0 />;
      case 1:
        return <Tab1 />;
      case 2:
        return <Tab2 />;
      case 3:
        return <Tab3 />;
      default:
        return <Tab4 />;
    }
  }

  render() {
    return (
      <>
        <section className="mb-6">
          <div className="container">
            <h1>Your Clinic's ART Data</h1>
            <div className="social_share">
              <a href="/#">
                <img src="images/facebook-32.png" alt="facebook" />
              </a>
              <a href="/#">
                <img src="images/twitter-32.png" alt="twitter" />
              </a>
              <div className="dropdown">
                <div className="dropdown-trigger">
                  <button
                    className="button is-nothing"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                  >
                    <img src="images/addthis-32.png" alt="add this" />
                  </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                  <div className="dropdown-content">
                    <a href="/#" className="dropdown-item">
                      <img
                        className="facebook"
                        src="images/facebook-16.png"
                        alt="facebook"
                      />
                      facebook
                    </a>
                    <a href="/#" className="dropdown-item">
                      <img
                        className="twitter"
                        src="images/twitter-16.png"
                        alt="twitter"
                      />
                      twitter
                    </a>
                    <a href="/#" className="dropdown-item">
                      <img
                        className="linkedin"
                        src="images/linkedin-16.png"
                        alt="linkedin"
                      />
                      linkedin
                    </a>
                    <a href="/#" className="dropdown-item">
                      <img
                        className="email"
                        src="images/email-16.png"
                        alt="Email"
                      />
                      Email
                    </a>
                    <a href="/#" className="dropdown-item">
                      <img
                        className="digg"
                        src="images/digg-16.png"
                        alt="digg"
                      />
                      digg
                    </a>
                    <a href="/#" className="dropdown-item">
                      Add this to your site
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-size-4 is-selfvcentered">
                <img
                  className="pr-3"
                  src="images/Shared.Images.Purple.PA.png"
                  alt="State clinic  Penn State Milton S. Hershey Medical Center"
                />
                Penn State Milton S. Hershey Medical Center
              </div>
              <div className="column">
                <div className="linl_block_wrap">
                  <div className="links_block">
                    <a href="/#">
                      <img
                        src="images/DRH_ART.Images.search_blue.png"
                        alt="search"
                      />
                      Find a Clinic
                    </a>

                    <a href="/#">
                      <img
                        className="ico_pdf"
                        src="images/Shared.Images.pdf_icon.gif"
                        alt="pdf"
                      />
                      Save as PDF (.pdf)
                    </a>
                    <a href="/#">
                      <img
                        src="images/DRH_ART.Images.US.png"
                        alt="View National Data"
                      />
                      View National Data
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs is-boxed tabs_block">
              <ul>
                <li
                  className={`${this.state.tabIndex === 0 ? "is-active" : ""}`}
                  >
                  <a href="/#" onClick={() => this.changeTabHandler(0)}>
                    Clinic Services
                  </a>
                </li>
                <li
                  className={`${this.state.tabIndex === 1 ? "is-active" : ""}`}
                >
                  <a href="/#" onClick={() => this.changeTabHandler(1)}>
                    Patient & Cycle Characteristics
                  </a>
                </li>
                <li
                  className={`${this.state.tabIndex === 2 ? "is-active" : ""}`}
                >
                  <a href="/#" onClick={() => this.changeTabHandler(2)}>
                    Success Rates: Patients Using Own Eggs
                  </a>
                </li>
                <li
                  className={`${this.state.tabIndex === 3 ? "is-active" : ""}`}
                >
                  <a href="/#" onClick={() => this.changeTabHandler(3)}>
                    Success Rates: Patients Using Donor Eggs
                  </a>
                </li>
                <li
                  className={`${this.state.tabIndex === 4 ? "is-active" : ""}`}
                >
                  <a href="/#" onClick={() => this.changeTabHandler(4)}>
                    Clinic Data Summary
                  </a>
                </li>
              </ul>
              <div className="tab-content">{this.currentTabHandler()}</div>
            </div>
          </div>
        </section>
        <section className="info_row">
          <div className="container">
            <div className="row_title">Related Links</div>
            <div className="row_body">
              <a href="https://www.womenshealth.gov/about-us" className="tp-link-policy" target="_blank" rel="noreferrer">
                HHS Office on Women's Health
              </a>
              <a href="https://www.sart.org/" target="_blank" rel="noreferrer">
                Society for Assisted Reproductive Technology (SART)
              </a>
              <a href="https://www.asrm.org/" target="_blank" rel="noreferrer">
                American Society for Reproductive Medicine (ASRM)
              </a>
              <a href="https://resolve.org/" target="_blank" rel="noreferrer">
                Resolve
              </a>
            </div>
          </div>
        </section>
      </>
    );
  }
}
