import React from "react";
import { returnDecimalNum } from "../../util";

const TabularChartReason = (props) => {
  const { data, dataNational } = props;
  const clinicId = sessionStorage.getItem('ClinicId');
  const showNational = clinicId === '9999' ? false : (props.showNational || false);

  return (
    <div className="table-container">
      <table
        className="table is-hoverable is-fullwidth table-bordered tabularChartTwo"
        cellSpacing="1"
      >
        <thead>
          <tr>
            <th className="th-tertiary" style={{textAlign: "center", backgroundColor: "#e3d3e4"}}>Factor</th>
            <th className="th-tertiary" style={{textAlign: "center", backgroundColor: "#e3d3e4"}}>
              {clinicId === '9999' ? 'National' : 'Clinic'} Percentage
            </th>
            {showNational && dataNational && (
              <th className="th-tertiary" style={{textAlign: "center", backgroundColor: "#e3d3e4"}}>
                National Percentage
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {data.DataRows.map((row, key) => (
            <tr key={key}>
              {row.map((col, index) => {
                if (index === 0) {
                  return (
                    <th className="th-tertiary" style={{backgroundColor: "#e3d3e4"}} key={col}>
                      <b>{col}</b>
                    </th>
                  );
                } else {
                  return (
                    <td style={{textAlign: "center"}} key={`clinic-${col}`}>
                      {returnDecimalNum(col)}
                    </td>
                  );
                }
              })}
              {showNational && dataNational && (
                <td style={{textAlign: "center"}} key={`national-${key}`}>
                  {returnDecimalNum(dataNational.DataRows[key][1])}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TabularChartReason;