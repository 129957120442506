import React from 'react';

const Footnotes = ({ footnotes, paginatedData }) => {
  if (!footnotes || !paginatedData || footnotes.length === 0 || paginatedData.length === 0) return null;

  // Get unique statuses from current page
  const pageStatuses = [...new Set(paginatedData.map(clinic => clinic.ClinicStatus))];
  
  // If footnotes exist in the API response and we have matching statuses on the page, show them
  const hasFootnoteableStatus = pageStatuses.length > 1;  // More than just "Open"
  
  if (!hasFootnoteableStatus) return null;

  // Function to determine class based on symbol character code
  const getFootnoteClass = (symbol) => {
    const charCode = symbol.charCodeAt(0);
    
    switch (charCode) {
      case 8224: // �
        return 'clinicStatusBottomNone';
      case 163:  // �
        return 'clinicStatusBottomReorganized';
      case 167:  // �
        return 'clinicStatusBottomClosed';
      default:
        return '';
    }
  };

  return (
    <div className="footnotes-section col mt-2">
      <div className="footnotes">
        {footnotes
          .sort((a, b) => parseInt(a.OrderBy) - parseInt(b.OrderBy))
          .map((footnote, index) => {
            const footnoteClass = getFootnoteClass(footnote.Symbol);
            return (
              <p key={index} className="mb-2">
                <span className={`footnote-symbol ${footnoteClass}`}>
                  {footnote.Symbol}
                </span>
                <span className="footnote-text ms-2">
                  {footnote.FootNote}
                </span>
              </p>
            );
          })}
      </div>
    </div>
  );
};

export default Footnotes;