// utility.js
import { useEffect, useRef } from 'react';

/**
 * Custom hook for setting document title and configuring metrics
 */

export const useSetTitle = (title, isArtDashboard = false) => {
    const previousTitle = useRef(null);
    const pageTitle = isArtDashboard
        ? `${title} | Assisted Reproductive Technology (ART) Success Rates | CDC`
        : `${title} | NASS Clinic Dashboard | CDC`;
    useEffect(() => {
        // Only update if the full page title has changed
        if (previousTitle.current !== pageTitle) {
            document.title = pageTitle;
            previousTitle.current = pageTitle;
            if (window._satellite && window.s) {
                console.log("Invoking useSetTitle()")
                trigger_pageview({
                    pageName: pageTitle,
                    siteSection: isArtDashboard ? 'ART Dashboard' : 'NASS Dashboard',
                    channel: isArtDashboard ? 'DRH ART Success Rates' : 'DRH NASS Clinic Dashboard',
                    force_pagename: pageTitle,
                    override_url_pagename: true
                });
            }
        }
    }, [pageTitle, isArtDashboard]);
};

function trigger_interaction(eventLabel = '', eventType, eventValue) {
    _check_initialized()
    _queue_action({
        function: '_trigger_interaction',
        eventLabel,
        eventType,
        eventValue
    })
    _process_queue()
}
// Track if we've moved away from Tab0 since page load
let hasLeftInitialTab = false;
let pageViewTimeout = null;

function trigger_pageview(dataObject) {
    // Skip if we haven't left Tab0 yet since page load
    if (!hasLeftInitialTab && dataObject.pageName.includes('Clinic Services & Profile')) {
        return;
    }

    // Mark that we've left Tab0
    hasLeftInitialTab = true;

    // Clear any pending page view
    if (pageViewTimeout) {
        clearTimeout(pageViewTimeout);
    }

    pageViewTimeout = setTimeout(() => {
        if (!window._satellite) {
            console.error('Adobe Launch not initialized');
            return;
        }

        const currentTitle = dataObject.pageName;

        // Set core variables in Adobe Analytics
        if (window.s) {
            window.s.pageName = currentTitle;
            window.s.channel = dataObject.channel;
            window.s.prop61 = currentTitle;
            window.s.prop69 = currentTitle;
            window.s.prop73 = window.location.href;
            window.s.eVar61 = currentTitle;
        }

        // Create enhanced data object
        const enhancedData = {
            ...dataObject,
            pageName: currentTitle,
            force_pagename: currentTitle,
            override_url_pagename: true,
            v61: currentTitle,
            prop61: currentTitle,
            prop69: currentTitle,
            prop73: window.location.href
        };

        console.log('Triggering pageview with data:', enhancedData);
        window._satellite.track('pageview', enhancedData);
    }, 100);
}

function _check_initialized() {
    window.CDC = window.CDC || {};
    window.CDC.SPA_App = window.CDC.SPA_App || {};

    if (!window.CDC.SPA_App.initialized) {
        const bodyElement = document.getElementsByTagName('body')[0];
        if (bodyElement && bodyElement.className.indexOf('adobe-launch-complete') !== -1) {
            window.CDC.SPA_App.initialized = true;

            // Configure Adobe Launch when initialized
            if (window._satellite) {
                window._satellite.configure({
                    pageNameOverride: true,
                    settings: {
                        useCustomPageName: true,
                        useDocumentTitle: true,
                        activitymap: {
                            useDocumentTitle: true
                        }
                    }
                });
            }
        } else if (!window.CDC.SPA_App.listenerAdded) {
            window.CDC.SPA_App.listenerAdded = true;
            bodyElement.addEventListener('adobe-launch-loaded', function () {
                window.CDC.SPA_App.initialized = true;

                // Configure Adobe Launch when it loads
                if (window._satellite) {
                    window._satellite.configure({
                        pageNameOverride: true,
                        settings: {
                            useCustomPageName: true,
                            useDocumentTitle: true
                        }
                    });
                }

                _process_queue();
            })
        }
    }
}

function _queue_action(action) {
    window.CDC.SPA_App.action_queue = window.CDC.SPA_App.action_queue || [];
    window.CDC.SPA_App.action_queue.push(action);
}

function _process_queue() {
    if (window.CDC.SPA_App.initialized && window.CDC.SPA_App.action_queue && window.CDC.SPA_App.action_queue.length > 0) {
        const actions = window.CDC.SPA_App.action_queue;
        window.CDC.SPA_App.action_queue = [];

        actions.forEach(function (action) {
            if (action.function === '_trigger_interaction') {
                _trigger_interaction(action.eventLabel, action.eventType, action.eventValue);
            } else if (action.function === '_trigger_pageview') {
                _trigger_pageview(action.dataObject)
            }
        })
    }
}

function _trigger_interaction(eventLabel, eventType, eventValue) {
    if (window.hasOwnProperty('_satellite')) {
        var dataObject = {};
        eventType = eventType || 'o';
        dataObject.ch = 'DRH';
        dataObject.pageName = document.title;
        dataObject.prop40 = eventValue;

        dataObject.label = eventLabel;
        dataObject.interactionType = eventType;
        dataObject.interactionValue = 'ci-' + eventLabel + ': ' + eventValue;

        dataObject.prop46 = window.location.href;

        console.log('adobe launch spa satellite track: interaction', dataObject);
        window._satellite.track('interaction', dataObject);
    } else {
        console.error('Analytics library finished rendering but satellite is undefined');
    }
}

function _trigger_pageview(dataObject) {
    if (window.hasOwnProperty('_satellite')) {
        const currentTitle = dataObject.pageName || document.title;

        // Create a consistent set of page variables
        const enhancedData = {
            ...dataObject,
            pageName: currentTitle,
            page: currentTitle,
            pid: currentTitle,
            v61: currentTitle,
            prop61: currentTitle,
            prop69: currentTitle,
            prop73: window.location.href,
            pageURL: '',
            pageURLOriginal: '',
            eVar61: currentTitle,
            force_pagename: currentTitle,
            override_url_pagename: true
        };

        console.log('adobe launch spa satellite track: pageview', enhancedData);
        window._satellite.track('pageview', enhancedData);
    } else {
        console.error('Analytics library finished rendering but satellite is undefined');
    }
}

export { trigger_interaction, trigger_pageview }