import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Add useNavigate
import './NavBar.css';

const NavBar = () => {
    const navigate = useNavigate();

    const handleNationalDataClick = (e) => {
        e.preventDefault(); // Prevent default link behavior
        sessionStorage.setItem('ClinicId', '9999');
        sessionStorage.setItem('Year', '0');

        //this is to determine if national data is clicked from module navigation or main navigation
        sessionStorage.setItem('NationalHeaderLinkClick', 'true');
        navigate('/');
        setTimeout(() => {
            window.location.reload();
        }, 100);
    };

    return (
        <nav className="bt-5 bt-gray-l3 custom-navbar navbar navbar-expand-lg py-0">
            <div className="container">
                <div className="navbar-nav">
                    <Link to="https://www.cdc.gov/art/success-rates/index.html" className="nav-link text-white p-3 mr-3">
                        ART Success Rates Home
                    </Link>
                    <a
                        href="/"
                        className="nav-link text-white p-3"
                        onClick={handleNationalDataClick}
                    >
                        National Data
                    </a>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;